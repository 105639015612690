import BaseRepository from "../BaseRepository";
import {DataType, Pin} from "../domain/ApiTypes";

/**
 * A service to provide data services for sessions
 */
export class PinDataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository(DataType.pin);
    }

    /**
     * Finds the pin with the given ID 
     */
    public find = (pinId: string) : Promise<Pin> =>
         this.repository.findSingleItem(pinId)
    

}

export const pinDataService = new PinDataService()