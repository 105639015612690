import { Dispatch } from "react";
import { updateStateWithObjects } from "../../../common/function/ActionFunctions";
import { Adventure, Game, Identity } from '../../../repository/domain/ApiTypes';
import { adventureDataService } from "../../../repository/service/AdventureDataService";
import { gameDataService } from "../../../repository/service/GameDataService";
import { AppState } from "../../../repository/state/AppState";
import { AppMode } from '../../../repository/state/UiState';
import {viewDataService} from "../../../repository/service/ViewDataService";


interface ActionParameters {
    type: string,
    children: Identity[],
}

/**
 * Loads the data for the selected game to start running it
 */
export class StartRunningGameAction {
    public type = 'StartGame';

    public build = (gameId: string) => (dispatch: Dispatch<any>, getState: () => AppState) => {
        //dispatch(startStoreOperation());   // TODO put this back in
        viewDataService.fetchDataForRunGame(gameId)
            .then((identities: Identity[]) => dispatch(this.buildReducer(identities)))
    }


    public buildReducer = (children: Identity[]) : ActionParameters => ({
        type: this.type,
        children,
    });

    public reduce = (state: AppState, action: ActionParameters) : AppState => (
        {
            ...updateStateWithObjects(state, action.children),
            ui: {...state.ui,
                mode: AppMode.RUN_GAME,
            },
        } as AppState
    )

}

const startRunningGameAction = new StartRunningGameAction();
export default startRunningGameAction;