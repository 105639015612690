import BaseRepository from "../BaseRepository";
import {DataType, Pin} from "../domain/ApiTypes";

/**
 * A service to provide data services for sessions
 */
export class SceneDataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository(DataType.scene);
    }

    /**
     * Finds the scene with the given ID
     */
    public find = (sceneId: string) => {
        return this.repository.findSingleItem(sceneId)
    }

    /**
     * Finds the actors in the given Scene
     */
    public findActors = (sceneId: string) : Promise<Pin[]> => {
        return this.repository.findSubObjects(sceneId, DataType.actor)
    }

    /**
     * Finds all scenes within the game with the given ID
     */
    public findAll = (gameId: string) => {
        return this.repository.findAllWithFilter(DataType.game, gameId);
    };

    public updatePosition = (sceneId: string, actorId: string, updatedPosition: number) =>
        this.repository.updateSubObjectWithBody(sceneId, DataType.actor, actorId, {order: updatedPosition})

    public addActor = (sceneId: string, actorId: string) =>
        this.repository.addNewSubObjectWithReference(sceneId, DataType.actor, actorId)

    public removeActor = (sceneId: string, actorId: string) =>
        this.repository.deleteSubObjectWithReference(sceneId, DataType.actor, actorId)

}

export const sceneDataService = new SceneDataService()