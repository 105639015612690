import {Dispatch} from "react"
import {Reducer} from "../../repository/AppReducer"
import {AppState} from "../../repository/state/AppState";

interface ActionParameters {
    type: string,
    message: string | null
}

/**
 * Shows or hides the progress dialog along with a message
 */
export class UpdateProgressDialog implements Reducer<ActionParameters> {
    public type = 'UpdateProgressDialog';


    public show = (message: string) => {
        return ({
            type: this.type,
            message,
        })
    }

    public hide = () => {
        return ({
            type: this.type,
            message: null,
        })
    }

    public reduce = (state: AppState, action: ActionParameters) : AppState => {
        return {
            ...state,
            ui: {
                ...state.ui,
                progressMessage: action.message,
            }
        }
    }
}

const updateProgressDialog = new UpdateProgressDialog();
export default updateProgressDialog;