import { Dispatch } from 'redux';
import { Reducer } from "../../repository/AppReducer";
import { Changes, Identity } from "../../repository/domain/ApiTypes";
import { AppState } from "../../repository/state/AppState";
import { updateStateWithChanges } from "../function/ActionFunctions";
import { refetchIdentity } from '../function/DataSyncFunctions';
import {SyncCommunicationChannel} from "../function/SyncCommunicationChannel";

interface ActionParameters {
    type: string,
    changes: Changes,
}

/**
 * Refetches a set of changes and updates the state with the given objects
 */
export class RefetchStoredObjects implements Reducer<ActionParameters> {
    public type = 'RefetchStoredObjects'

    public build = (changes: Changes) => (dispatch: Dispatch<any>) => {
        const refetchPromises: Promise<any>[] = []
        const deletions: Identity[] = changes.deletions ?? []

        if(changes.primary) {
            const refetch = refetchIdentity(changes.primary)
            if(refetch) refetchPromises.push(refetch)
        }

        if(changes.others) {
            changes.others.forEach(o => {
                const refetch = refetchIdentity(o)
                if(refetch) refetchPromises.push(refetch)
            })
        }

        Promise.all(refetchPromises)
            .then(refetched => {
                const updatedChanges: Changes = {
                    others: refetched,
                    deletions: deletions
                }
                SyncCommunicationChannel.shareLocalChange(updatedChanges)
                dispatch({type: this.type, changes: updatedChanges})
            })
    }

    public reduce = (state: AppState, action: ActionParameters) : AppState => {
        return updateStateWithChanges(state, action.changes)
    };
}

const refetchStoredObjects = new RefetchStoredObjects()
export default refetchStoredObjects