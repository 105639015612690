import {Changes, Identity, Scene} from './../domain/ApiTypes';
import {IllegalArgumentException} from './../../common/exception/Exceptions';
import BaseRepository from "../BaseRepository";
import {PLAYER_API_KEY} from "../domain/ApiConstants";
import {DataType, Game, NewGame, PartialGame} from "../domain/ApiTypes";
import {DataService} from "./DataService";
import {TypedMap} from "../domain/MapTypes.ds";

/**
 * A service to provide data services to the plot editor
 */
export class GameDataService implements DataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository(DataType.game);
    }

    /**
     * Adds a new unnamed game referring to the given adventure
     * @returns a promise returning the updated item
     */
    public create = (newGame: NewGame) => {
        if(newGame.adventureId) {
            return this.repository.createWithDetails(newGame)         
        }

        throw new IllegalArgumentException()
    };


    /**
     * Saves the given updates to the game with the given ID
     */
    public updateItemFields = (gameId: string, updated: PartialGame | Partial<Game>): Promise<Game> => {
        return this.repository.updateItem(gameId, updated) as Promise<Game>
    };

    /**
     * Finds all items
     * @return Game[]
     */
    public findAll = (): Promise<Game[]> => {
        return this.repository.findAllItems();
    };

    /**
     * Find a single item with the given id
     * @param {string} id the ID to find
     * @return {game} the item that was found
     */
    public findOne = (id: string) => {
        return this.repository.findSingleItem(id);
    };

    /**
     * Finds all games the current user is playing in along with their children
     */
    public findAllPlayingInWithChildren = (playerId: string): Promise<Identity[]> => {
        const params: TypedMap<string> = {}
        params[PLAYER_API_KEY] = playerId
        return this.repository.search(params)
    }

    /**
     * Finds all child data of the given game
     */
    public findChildren = (gameId: string) : Promise<Identity[]> => 
        this.repository.findSubObjects(gameId, "children")

    /**
     * Finds all child data of the given game that show on the lobby
     */
    public findChildrenOnLobby = (gameId: string) : Promise<Identity[]> =>
        this.repository.findSubObjects(gameId, "children-lobby")

    /**
     * Finds all child data of the given game, but only those visible to a player
     */
    public findChildrenVisibleToPlayer = (gameId: string) : Promise<Identity[]> =>
        this.repository.findSubObjects(gameId, "children-player")

    /**
     * Removes an item from being part of the given item
     * @param id the ID to be removed
     * @returns a promise with the updated items
     */
    public delete = (id: string) => {
        return this.repository.deleteItem(id)
    };

    /**
     * Adds the given player to the given game
     */
    public addPlayer = (id: string, playerId: string): Promise<Game> =>
        this.repository.addNewSubObjectWithReference(id, DataType.player, playerId);

    /**
     * Removes the given player from the given game
     */
    public removePlayer = (id: string, playerId: string): Promise<Game> =>
        this.repository.deleteSubObjectWithReference(id, DataType.player, playerId);

    /**
     * Adds the given clip as a bookmark
     */
    public addBookmark(gameId: string, clipId: string) : Promise<Game> {
        return this.repository.addNewSubObjectWithReference(gameId, DataType.bookmark, clipId)
    }
   
    /**
     * Removes the given clip from the bookmarks
     */
    public removeBookmark(gameId: string, clipId: string) : Promise<Game> {
        return this.repository.deleteSubObjectWithReference(gameId, DataType.bookmark, clipId) 
    }

    /**
     * Starts a scene with the board at the given clip
     */
    public startScene = (gameId: string, boardId: string, partial: Partial<Scene>): Promise<Changes> => {
        return this.repository.addNewSubObjectWithReferenceAndBody(gameId, DataType.scene, boardId, partial)
    }

    /**
     * Ends the given scene
     */
    public endScene = (gameId: string, sceneId: string, partial: Partial<Scene>): Promise<Changes> => {
        return this.repository.updateSubObjectWithBody(gameId, DataType.scene, sceneId, partial)
    }
    

}

export const gameDataService = new GameDataService()