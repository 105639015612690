import { Reducer } from "../../../repository/AppReducer";
import { AppState } from "../../../repository/state/AppState";

interface ActionParameters {
    type: string,
    clipId: string,
    informationId: string
}

// TODO: Remove, no longer used

/**
 * Changes the state to have the given clip (by ID) and information (by ID) selected
 */
export class SelectClipAndInformationAction implements Reducer<ActionParameters> {
    public type = 'SelectClipAndInformationAction';

    public build = (clipId: string, informationId: string) : ActionParameters => ({
        type: this.type,
        clipId,
        informationId
    });

    public reduce = (state: AppState, action: ActionParameters) : AppState => {
        return {
            ...state,
            ui: {
                ...state.ui,
                selectedClipId: action.clipId,
                editingInformationId: action.informationId
            }
        }
    };
}

const selectClipAndInformationAction = new SelectClipAndInformationAction();
export default selectClipAndInformationAction;