import {Dispatch} from "react";
import { Reducer } from "../../../repository/AppReducer";
import { Clip } from "../../../repository/domain/ApiTypes";
import { TypedMap } from "../../../repository/domain/MapTypes.ds";
import { clipDataService } from "../../../repository/service/ClipDataService";
import { AppState } from "../../../repository/state/AppState";
import { PrimaryView } from "../../../repository/state/UiState";

interface Parameters {
    type: string,
    clipId: string
}

/**
 * Deletes the given clip from the state
 */
export class DeleteClipAction implements Reducer<Parameters> {
    public type = 'DeleteClipAction';

    public build = (clipId: string) => (dispatch: Dispatch<any>, getState: () => AppState) => {
        // dispatch(startStoreOperation());   // TODO put this back in

        clipDataService.delete(clipId)
            .then((deletedItem) => {
                dispatch(this.buildReducer(clipId))
            });

    };

    public buildReducer = (clipId: string) : Parameters => ({
        type: this.type,
        clipId
    });

    public reduce = (state: AppState, action: Parameters) : AppState => {
        const updatedClips: TypedMap<Clip> = {...state.clips};
        delete updatedClips[action.clipId];

        let updatedSelectedClipId = state.ui.selectedClipId;
        let updatedUi = state.ui;
        if(updatedSelectedClipId === action.clipId) {
            updatedSelectedClipId = null;
            if(updatedUi.primaryView === PrimaryView.CLIP) {
                updatedUi = {
                    ...state.ui,
                    primaryView: PrimaryView.OVERVIEW,
                    selectedClipId: updatedSelectedClipId
                }
            }
        }

        // TODO update entries about or from clip as well

        return {
            ...state,
            clips: updatedClips,
            ui: updatedUi
        }
    };
}

const deleteClipAction = new DeleteClipAction();
export default deleteClipAction;