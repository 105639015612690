
export const SESSIONS_TYPE: string = "sessions";
export const CURRENT_SESSION_TYPE: string = "currentSession";

export const ENTRY_TYPE = "entry";
export const ENTRIES_TYPE = "entries";

export const ABOUT_API_KEY: string = "about";
export const KNOWN_BY_API_KEY: string = "known-by";
export const CURRENT_USER_PLAYING_API_KEY: string = "current-user-playing";
export const PLAYER_API_KEY: string = "player"

export const NAME_FIELD: string = "name";

export const COLLECTION_URL_FILTER = "collection";

export const CURRENT_USER_API_KEY = "current";
export const CHARACTERS_FOR_CURRENT_USER_API_KEY = "current-user"
export const CHILDREN_API_KEY = "children";



