import {Dispatch} from "react";
import {updateStateWithObjects} from "../function/ActionFunctions";
import {Reducer} from "../../repository/AppReducer";
import {Identity} from '../../repository/domain/ApiTypes';
import {AppState} from "../../repository/state/AppState";
import {getDataService} from '../../repository/service/DataService';
import {SyncCommunicationChannel} from "../function/SyncCommunicationChannel";

interface Parameters {
    type: string,
    updated: Identity
}

/**
 * Updates the data in any adventure object
 * TODO use correct Partial objects
 * TODO resolve the difference between this and UpdateStoredObjects
 */
export class UpdateObject implements Reducer<Parameters> {
    public type = 'UpdateObject';

    public buildExecutingFirst = (f : () => Promise<Identity>) => (dispatch: Dispatch<any>, getState: () => AppState) => 
        f().then(update => dispatch(this.buildReducer(update)))
    
    public buildFromPromise = (update : Promise<Identity>) => (dispatch: Dispatch<any>, getState: () => AppState) => 
        update.then(u => dispatch(this.buildReducer(u)))

    public build = (original: Identity, updates: any) => (dispatch: Dispatch<any>) => {
        // dispatch(startStoreOperation());   // TODO put this back in

        getDataService(original.type).updateItemFields(original.id, updates)
            .then((updated) => {
                dispatch(this.buildReducer(updated))
            });

    };

    public buildReducer = (updated: Identity) : Parameters => ({
        type: this.type,
        updated
    });

    public reduce = (state: AppState, action: Parameters) : AppState => {
        SyncCommunicationChannel.shareLocalChange({primary: action.updated})
        return updateStateWithObjects(state,[action.updated])
    };
}

const updateObject = new UpdateObject();
export default updateObject;