import { JournalEntry } from './../domain/ApiTypes';
import BaseRepository from "../BaseRepository";
import {DataType, PartialJournalEntry} from "../domain/ApiTypes";

/**
 * A service to provide data services for journal entries
 */
export class JournalEntryDataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository(DataType.entry);
    }

    
    /**
     * Finds the entry with the given ID
     */
     public findOne = (entryId: string) => {
        return this.repository.findSingleItem(entryId)
    }


    /**
     * Updates a writing entry with the given partial details
     */
    public updateWritingEntry = (id: string, entry: PartialJournalEntry) : Promise<JournalEntry> =>
        this.repository.updateItem(id, entry) as Promise<JournalEntry>

}

export const journalEntryDataService = new JournalEntryDataService()