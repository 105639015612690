import { Dispatch } from "react";
import { Reducer } from "../../repository/AppReducer";
import { DataType, Identity } from '../../repository/domain/ApiTypes';
import { getDataService } from '../../repository/service/DataService';
import { AppState } from "../../repository/state/AppState";
import { updateStateWithObjects } from "../function/ActionFunctions";

interface Parameters {
    type: string,
    created: Identity
}

/**
 * Creates any adventure object
 * TODO use correct Partial objects
 */
export class CreateObject implements Reducer<Parameters> {
    public type = 'CreateObject';

    public build = (type: DataType, partial: any) => (dispatch: Dispatch<any>) => {
        // dispatch(startStoreOperation());   // TODO put this back in

        getDataService(type).create(partial)
            .then((created) => {
                dispatch(this.buildReducer(created))
            });

    }

    public buildWithOwner = (type: DataType, partial: any, owner: Identity) => (dispatch: Dispatch<any>) => {
        // dispatch(startStoreOperation());   // TODO put this back in

        getDataService(type).create(partial, owner)
            .then((created) => {
                dispatch(this.buildReducer(created))
            });

    };

    public buildReducer = (created: Identity) : Parameters => ({
        type: this.type,
        created: created
    });

    public reduce = (state: AppState, action: Parameters) : AppState => {
        return updateStateWithObjects(state,[action.created])
    };
}

const createObject = new CreateObject();
export default createObject;