import BaseRepository from "../BaseRepository";
import {UserWithPlayers, User, DataType, Identity} from "../domain/ApiTypes";

/**
 * A service to provide data services to the plot editor
 */
export class SearchDataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository("search");
    }

    /**
     * Searches for users based on a substring and returns the users
     */
    public search = (collectionId: string, types: DataType[], searchText: string) : Promise<Identity[]> => {
        return this.repository.findMatchingItemsInCollection(collectionId, types, searchText);
    };

    /**
     * Searches for users based on a substring and returns the users
     */
    public searchForUsers = (searchText: string) : Promise<User[]> => {
        return this.repository.searchForSubtype("users", searchText);
    };

    /**
     * Searches for users based on a substring and returns the users and their characters
     */
    public searchForUsersWithPlayers = (searchText: string) : Promise<UserWithPlayers[]> => {
        return this.repository.searchForSubtype("users-players", searchText);
    };

}

export const searchDataService = new SearchDataService()