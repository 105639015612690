import { getCommChannelConfig } from '../../../common/function/DataSyncFunctions';
import { Dispatch } from "redux";
import { updateStateWithObjects } from "../../../common/function/ActionFunctions";
import { Reducer } from "../../../repository/AppReducer";
import { Adventure, DataType, Identity } from "../../../repository/domain/ApiTypes";
import { adventureDataService } from "../../../repository/service/AdventureDataService";
import { AppState } from "../../../repository/state/AppState";
import { AppMode } from "../../../repository/state/UiState";
import { SyncCommunicationChannel } from '../../../common/function/SyncCommunicationChannel';

interface ActionParameters {
    type: string,
    adventure: Adventure,
    children: Identity[],
}

/**
 * Loads the data for the selected adventure to start editing it
 */
export class StartEditingAdventureAction implements Reducer<ActionParameters> {
    public type = 'StartEditingAdventureAction';

    public build = (adventureId: string) => (dispatch: Dispatch<any>, getState: () => AppState) => {
        // dispatch(startStoreOperation());   // TODO put this back in

        Promise.all([
            adventureDataService.findOne(adventureId),
        ]).then(([adventure]) => {
            Promise.all([
                adventureDataService.findChildren(adventure.id),
            ]).then(([adventureChildren]) => {    
                dispatch(this.buildReducer(
                    adventure,
                    adventureChildren))
            })
        })
    }
    
    public buildReducer = (adventure: Adventure, children: Identity[]) : ActionParameters => ({
        type: this.type,
        adventure,
        children,
    });

    public reduce = (state: AppState, action: ActionParameters) : AppState => (
        {
            ...updateStateWithObjects(state, action.children),
            adventure: action.adventure,
            ui: {...state.ui,
                mode: AppMode.EDIT_ADVENTURE,
            },
        } as AppState
    )

}

const startEditingAdventureAction = new StartEditingAdventureAction();
export default startEditingAdventureAction;