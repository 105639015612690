import BaseRepository from "../BaseRepository";
import {Clip, DataType} from "../domain/ApiTypes";

/**
 * A service to provide data services for accessing Clip objects
 */
export class ClipProxyDataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository(DataType.clipProxy);
    }

    /**
     * Find a single item with the given id
     * @param {string} id the ID to find
     * @return {Clip} a promise with the item that was found
     */
    public findOne = (id: string) => {
        return this.repository.findSingleItem(id);
     };

}

export const clipProxyDataService = new ClipProxyDataService()
