import { Auth } from 'aws-amplify';
import { Dispatch } from 'react';
import { Reducer } from "../../repository/AppReducer";
import { AppState } from "../../repository/state/AppState";
import { initialState } from './../../repository/state/AppState';

interface ActionParameters {
    type: string,
}

/**
 * Logs the user out and sends them back to the login screen
 */
export class UserLogout implements Reducer<ActionParameters> {
    public type = 'UserLogout';

    public build = () => (dispatch: Dispatch<any>) => {
        // dispatch(startStoreOperation());   // TODO put this back in
        Auth.signOut()
            .then(() => {
                dispatch(this.buildReducer())
            });
    }

    public buildReducer = (): ActionParameters => ({
        type: this.type,
    })

    public reduce = (state: AppState, action: ActionParameters): AppState => {
        return {...initialState}
    }
}

const userLogout = new UserLogout();
export default userLogout;