import { Dispatch } from "react";
import { Reducer } from "../../repository/AppReducer";
import { AppState, initialState } from '../../repository/state/AppState';

interface ActionParameters {
    type: string
    state: AppState
}

/**
 * Clears the current state back to the initial state
 */
export class ResetStateAction implements Reducer<ActionParameters> {
    public type = "ResetState"

    public build = () => (dispatch: Dispatch<any>) => {
        dispatch({
            type: this.type,
            state: initialState
        })
    };

    public reduce = (state: AppState, action: ActionParameters) : AppState => {
        return {
            ...action.state,
            user: state.user,
        }
    };
}

const resetStateAction = new ResetStateAction();
export default resetStateAction;