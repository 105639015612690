import { SystemSetupException } from '../../common/exception/Exceptions';
import { DataType } from "../domain/ApiTypes";
import { Identity } from './../domain/ApiTypes';
import { playerDataService } from './PlayerDataService';

/**
 * A interfaces for classes that provide data services
 * TODO use generics
 */
export interface DataService {
   // TODO Get rid of these methods or even the class, if necessary

    /**
     * Find a single item with the given id
     * @param {string} id the ID to find
     * @return {Clip} a promise with the item that was found
     */
    findOne: (id: string) => Promise<any>

    /**
     * Creates a object with the given owner
     */
    create: (partial: any, owner?: Identity) => Promise<any>

    /**
     * Writes the updated data into the database
     * if necessary
     * @param item plot item to be updated
     * @param updated the updated item, may contain multiple updated fields
     */
    updateItemFields: (id: string, updated: any) => Promise<any>

}


export const getDataService = (type: DataType) : DataService => {
    switch(type) {
        case DataType.player:
            return playerDataService
        default:
            throw new SystemSetupException()
    }
}