import {FederatedPointerEvent} from "@pixi/events";
import {PinView} from "../view/PinView";
import {Board, Pin} from "../../../repository/domain/ApiTypes";


export enum BoardTags {
    selected = "selected",
    player = "player",
    friendly = "friendly",
    locked = "locked",
    owned = "owned",
    knockedOut = "knockedOut",
}

export const ViewBoardTags: BoardTags[] = [BoardTags.selected, BoardTags.locked, BoardTags.owned]

export enum PinChangeAction {
    update = "update",
    delete = "delete",
}

export enum ActorsChangeAction {
    add = "add",
    remove = "remove",
}

export enum VeilActionMode {
    off = "off",
    add = "add",
    remove = "remove",
}

/** Fired when a pin changes */
export type PinChangedHandler = (pin: PinView, action: PinChangeAction) => void

/** Called to set the ref to be used as the home of the Pixi.js view */
export type SetRefFunction = (ref: HTMLDivElement) => void

/** A function to push a change to a pin on the screen */
export type ChangePinFunction = (pinId: string, partial: Partial<Pin>, action: PinChangeAction) => void

/** A function to add/remove an actor on the actor list */
export type ChangeActorsFunction = (pinId: string, action: ActorsChangeAction) => void

/** A function to preview a change to the board on the screen */
export type ChangeBoardFunction = (partial: Partial<Board>) => void

export type PinClickedHandler = (pin: PinView, event: FederatedPointerEvent) => void
export type PinPressedHandler = (pin: PinView, event: FederatedPointerEvent) => void
export type PinDefaultActionHandler = (pin: PinView, event: FederatedPointerEvent) => void

export type EventHandler = (e: FederatedPointerEvent) => void
