import {Dispatch} from "redux";
import {Reducer} from "../../../repository/AppReducer";
import {Identity} from "../../../repository/domain/ApiTypes";
import {AppState, clearStateDownToGame} from "../../../repository/state/AppState";
import {AppMode} from "../../../repository/state/UiState";
import {updateStateWithObjects} from '../../../common/function/ActionFunctions';
import {viewDataService} from "../../../repository/service/ViewDataService";

interface ActionParameters {
    type: string,
    items: Identity[],
}

/**
 * Loads the data for the Player Editor
 */
export class PlayerInGame implements Reducer<ActionParameters> {
    public type = 'PlayerInGame'

    public load = (playerId: string, gameId: string) => (dispatch: Dispatch<any>, getState: () => AppState) => {
        //dispatch(startStoreOperation());   // TODO put this back in
        viewDataService.loadPlayerInSharedGame(gameId, playerId)
            .then( items => dispatch(this.buildReducer(items)))
    }

    public buildReducer = (items: Identity[]) : ActionParameters => ({
        type: this.type,
        items,
    })

    public reduce = (state: AppState, action: ActionParameters) : AppState => {
        const newState = clearStateDownToGame(state)
        return updateStateWithObjects(newState, action.items)
    }

}

const playerInGame = new PlayerInGame();
export default playerInGame;