import BaseRepository from "../BaseRepository";
import {
    Changes,
    DataType,
    PartialBoard,
    PartialMediaReference,
    PartialPin,
    Pin, PolygonGraphic,
    RegularPolygonGraphic
} from "../domain/ApiTypes";
import {ApiContext} from "./ApiContext";

/**
 * A service to provide data services for media items
 */
export class BoardDataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository(DataType.board);
    }

    /**
     * Finds a board 
     */
    public find = (boardId: string, apiContext?: ApiContext) =>
       this.repository.findSingleItem(boardId, apiContext)

    /**
     * Pins the given object to the given board
     */
    public pinToBoard = (boardId: string, dataType: DataType, partialPin: PartialPin) : Promise<Changes> =>
        this.repository.addNewSubObjectWithBody(boardId, this.pinApiString(dataType), partialPin);

    /**
     * Pins the given object to the given board
     */
    public pinCopyToBoard = (boardId: string, pinId: string, partialPin: PartialPin) : Promise<Changes> =>
        this.repository.addNewSubObjectWithReferenceAndBody(boardId, "pin-copy", pinId, partialPin);

    /**
     * Moves the given pin on the given board
     */
    public updatePin = (boardId: string, pinId: string, partialPin: Partial<Pin>) : Promise<Pin> =>
        this.repository.updateSubObjectWithBody(boardId, DataType.pin, pinId, partialPin);

    /**
     * Removes the given pin from the given board the given board
     */
    public removePinFromBoard = (boardId: string, pinId: string) : Promise<Changes> =>
        this.repository.deleteSubObjectWithReference(boardId, DataType.pin, pinId);

    /**
     * Returns the pins on the board that belong to the current user
     * @param boardId
     */
    public findCurrentUsersPins = (boardId: string, playerId: string) : Promise<Pin[]> =>
        this.repository.searchForSubObjects(boardId, DataType.pin, DataType.player, playerId)

    /**
     * Adds the given media to the given clip
     * @param boardId the ID of board that is to be added to
     * @param mediaId the ID of the media to be added
     * @returns a promise with the updated item
     */
    public addMedia(boardId: string, mediaId : string) {
        return this.repository.addMedia(boardId, mediaId)
    }

    /**
     * Updates the data on the given media reference
     */
    public updateMediaReference(boardId: string, mediaId : string, updates: PartialMediaReference) {
        return this.repository.updateMediaReference(boardId, mediaId, updates)
    }

    /**
     * Removes the given media from the given clip
     * @param boardId the ID of board that is to be added to
     * @param mediaId the ID of the media to be added
     * @returns a promise with the updated item
     */
    public removeMedia(boardId: string, mediaId : string) {
        return this.repository.removeMedia(boardId, mediaId)
    }

    /**
     * Saves the updated board in the database
     */
    public updateBoard = (id: string, partialBoard: PartialBoard) => {
        return this.repository.updateItem(id, partialBoard)
    }

    /**
     * Saves the veils at the given points
     */
    public addVeils = (id: string, veils: RegularPolygonGraphic[]) => {
        return this.repository.addNewSubObjectWithBody(id, "veil", veils)
    }

    /**
     * Deletes the veils at the given points
     */
    public removeVeils = (id: string, veils: RegularPolygonGraphic[]) => {
        return this.repository.deleteNewSubObjectsDescribedInBody(id, "veil", veils.map(v => v.localId))
    }


    /**
     * Saves the area with the given points
     */
    public addAreas = (id: string, areas: PolygonGraphic[]) => {
        return this.repository.addNewSubObjectWithBody(id, "area", areas)
    }

    /**
     * Deletes the given areas
     */
    public removeAreas = (id: string, veils: PolygonGraphic[]) => {
        return this.repository.deleteNewSubObjectsDescribedInBody(id, "area", veils.map(v => v.localId))
    }


    public pinApiString = (type: DataType) => {
        switch(type) {
            case DataType.character:
            case DataType.location:
            case DataType.plot:
            case DataType.clip: return "pin-clip"
            case DataType.media: return "pin-media"
            case DataType.clipProxy: return "pin-proxy"
            default: return "bad-type" // TODO better error handling
        }
    }


}
     


export const boardDataService = new BoardDataService()