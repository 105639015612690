import {DataNotLoadedException} from "../../common/exception/Exceptions";
import {Adventure, Clip, Game, Identity, Player} from "../../repository/domain/ApiTypes";
import {AppState} from "../../repository/state/AppState";
import {AppMode} from "../../repository/state/UiState";


/**
 * Returns the currently loaded adventure
 */
export const getAdventure = (state: AppState) : Adventure => {
    if(state.adventure) return state.adventure;
    throw new DataNotLoadedException()
};

/**
 * Returns the currently loaded game or throws an error
 */
 export const getGame = (state: AppState) : Game => {
    if(state.game) return state.game
    throw new DataNotLoadedException()
}


/**
 * Returns the currently loaded adventure's ID
 */
export const getAdventureId = (state: AppState) : string => 
    getAdventure(state).id

export const getGameOrNull = (state: AppState) => state.game;

export const getGameId = (state: AppState) : string | undefined => 
    getGameOrNull(state)?.id

export const isGameBeingRun = (state: AppState) =>
    state.game !== null && state.ui.mode === AppMode.RUN_GAME;

export const isGameBeingPlayed = (state: AppState) =>
    state.game !== null && (state.ui.mode === AppMode.PLAY_GAME || state.ui.mode === AppMode.PLAY_GAME_SHARED)

export const getBookmarks = (state: AppState) : Clip[] => {
    const bookmarkIds : Identity[] = (isGameBeingRun(state) ? state?.game?.bookmarks : state.adventure?.bookmarks) || []
    return bookmarkIds.map(bid => state.clips[bid.id])
}

/**
 * Returns all the built-in Players in the Adventure
 */
export const getAllBuiltInPlayers = (state: AppState): Player[] => {
    if(!state.adventure) throw new DataNotLoadedException()
    return state.adventure.builtInPlayers
        .map(p => state.participantPlayers[p.id])
}
    