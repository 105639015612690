import {AppState} from "../../../repository/state/AppState";
import {Reducer} from "../../../repository/AppReducer";

interface Parameters {
    type: string,
    sessionId: string
}

/**
 * Sets the selected session ID as the current one in the journal viewer
 */
export class SelectSession implements Reducer<Parameters> {
    public type = 'SelectSession';

    public build = (sessionId: string) => ({
        type: this.type,
        sessionId
    });

    public reduce = (state: AppState, action: Parameters) : AppState => {
        return ({
            ...state,
            ui: {...state.ui, viewingSessionId: action.sessionId }
        });
    }
}

const selectSession = new SelectSession();
export default selectSession;