import {Dispatch} from "redux";
import {updateStateWithObjects} from "../../../common/function/ActionFunctions";
import {Reducer} from "../../../repository/AppReducer";
import {Identity, Player} from "../../../repository/domain/ApiTypes";
import {playerDataService} from '../../../repository/service/PlayerDataService';
import {AppState} from "../../../repository/state/AppState";
import {AppMode} from "../../../repository/state/UiState";

interface ActionParameters {
    type: string,
    player: Player,
    children: Identity[],
}

/**
 * Loads the data for the selected Player to start editing it
 */
export class PlayerStandalone implements Reducer<ActionParameters> {
    public type = 'PlayerStandalone';

    public load = (playerId: string) => (dispatch: Dispatch<any>, getState: () => AppState) => {
        //dispatch(startStoreOperation());   // TODO put this back in

        // TODO change to use View Controller
        Promise.all([
            playerDataService.findOne(playerId),
        ]).then(([player]) => {
            Promise.all([
                playerDataService.findChildren(player.id),
            ]).then(([playerChildren]) => {
                dispatch(this.buildReducer(
                    player, 
                    playerChildren))
            })
        })
    }

    public buildReducer = (player: Player, children: Identity[]) : ActionParameters => ({
        type: this.type,
        player,
        children,
    });

    public reduce = (state: AppState, action: ActionParameters) : AppState => {
        return {
            ...updateStateWithObjects(state, action.children),
            player: action.player,
            adventure: null,
            game: null,
            ui: {...state.ui,
                mode: AppMode.EDIT_PLAYER,
            },
        } as AppState
    };

}

const playerStandalone = new PlayerStandalone();
export default playerStandalone;