import BaseRepository from "../BaseRepository";
import {DataType, Identity} from "../domain/ApiTypes";

/**
 * A service to provide data services for getting the stage
 */
export class StageDataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository(DataType.stage);
    }

    /**
     * Loads the stage
     */
    public fetch = (stageId: string) => {
        return this.repository.findSingleItem(stageId);
    }

    /**
     * Loads the stage's children
     */
    public fetchChildren = (stageId: string) : Promise<Identity[]> => {
        return this.repository.findSubObjects(stageId, "children");
    }

    /**
     * Shows the given media on the stage
     */
    public addToStage = (stageId: string, objId: string) =>
        this.repository.addNewSubObjectById(stageId, objId);

    /**
     * Shows the given media on the stage
     */
    public removeFromStage = (stageId: string, objectId: string) =>
        this.repository.deleteSubObjectById(stageId, objectId);

    /**
     * Shows the given Board on the stage
     */
    public putBoardOnStage = (stageId: string, boardId: string) =>
        this.repository.setSubObjectToReference(stageId, DataType.board, boardId);

    /**
     * Shows the given media on the stage
     */
    public removeBoardFromStage = (stageId: string) =>
        this.repository.deleteSubObject(stageId, DataType.board);

}

export const stageDataService = new StageDataService()