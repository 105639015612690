import createCachedSelector from "re-reselect";
import {createSelector} from "reselect";
import {buildInternalIconSourceFromKey, getDefaultIconMediaForType, getInternalMedia} from "../../assets/InternalMedia";
import {getAllClipsMap} from "../../feature/clip/ClipFunctions";
import {getAllInformationMap} from "../../feature/information/InformationFunctions";
import {
    Clip,
    DataType,
    HasInformation, HasMedia,
    Identity,
    Information,
    Media,
    MediaKeyType,
    MediaPurpose,
    MediaReference
} from "../../repository/domain/ApiTypes";
import {CloudinaryUploadResult} from "../../repository/domain/CloudinaryTypes";
import {TypedMap} from "../../repository/domain/MapTypes.ds";
import {AppState} from "../../repository/state/AppState";
import {getUiState} from "../../repository/UiFunctions";
import {DataNotLoadedException} from "../exception/Exceptions";
import {
    adventureMediaDataService,
    boardMediaDataService,
    characterMediaDataService,
    gameMediaDataService,
    informationMediaDataService, itemMediaDataService,
    locationMediaDataService,
    MediaOwningDataService,
    playerMediaDataService,
    plotMediaDataService,
    userMediaDataService
} from './../../repository/service/OwningMediaDataService';
import {CenteredGraphics, centerInBounds, centerInBoundsAtPoint} from "./DrawingFunctions";

/**
 * Returns true if the given identity is a media
 */
export const isMedia = (identity: Identity) => identity.type === DataType.media
export const filterMedia = (identities: Identity[]) => identities.filter(c => isMedia(c)).map(c => c as Media)

/**
 * Returns the appropriate data service that manages the owners of media, given the data type passed in
 */
export const getMediaOwningDataService = (ownerType: DataType): MediaOwningDataService => {
    switch(ownerType) {
        case DataType.adventure: return adventureMediaDataService
        case DataType.game: return gameMediaDataService
        case DataType.player: return playerMediaDataService
        case DataType.character: return characterMediaDataService
        case DataType.location: return locationMediaDataService
        case DataType.item: return itemMediaDataService
        case DataType.plot: return plotMediaDataService
        case DataType.information: return informationMediaDataService
        case DataType.board: return boardMediaDataService
        case DataType.user: return userMediaDataService
    }

    throw new DataNotLoadedException()
};

export const getAllMediaMap = (state: AppState): TypedMap<Media> => {
    if (state.media) {
        return state.media
    }
    throw new DataNotLoadedException()
};

export const getMedia = createCachedSelector(
    getAllMediaMap,
    (state: AppState, mediaId: string) => mediaId,
    (allMedia, mediaId) => allMedia[mediaId],
)(
    (_, mediaId) => mediaId
);

/**
 * Returns the source
 */
export const getIconSourceWithDefaults = createCachedSelector(
    getAllMediaMap,
    (state: AppState, owner: HasMedia) => owner,
    (allMedia, owner) => {
        const iconId = getIconId(owner)
        const media = getMediaWithDefaults(allMedia, iconId, owner.type)
        return buildMediaSource(media)
    }
)(
    (_, owner) => owner.id
);

/**
 * Returns the source
 */
export const getIconSourceOrNull = createCachedSelector(
    getAllMediaMap,
    (state: AppState, owner: HasMedia) => owner,
    (allMedia, owner) => {
        const iconId = getIconId(owner)
        if(iconId) {
            const media = getMediaWithDefaults(allMedia, iconId, owner.type)
            return buildMediaSource(media)
        }
        else {
            return null
        }
    }
)(
    (_, owner) => owner.id
);


/**
 * Returns the icon media of the given owning object, otherwise return a default based on the object
 * type
 */
 export const getIconWithDefaults = createCachedSelector(
    getAllMediaMap,
    (state: AppState, owner: HasMedia) => owner,
    (allMedia, owner) => {
        const iconId = getIconId(owner)
        return getMediaWithDefaults(allMedia, iconId, owner.type)
    }
)(
    (_, owner) => owner.id
);


/**
 * Gets the media object from all current media with the given ID - the search includes the built-ins
 */
export const getMediaWithInternals = (allMedia: TypedMap<Media>, id: string) => {
    let media = allMedia[id]
    if (!media) {
        media = getInternalMedia(id)
    }

    return media
}

/**
 * Gets the media object from all current media with the given ID - the search includes the built-ins
 */
export const getMediaWithDefaults = (allMedia: TypedMap<Media>, id: string | null | undefined, type: DataType) => {
    if(!id) {
        return getDefaultIconMediaForType(type)
    }
    let media = allMedia[id]
    if (!media) {
        media = getDefaultIconMediaForType(type)
    }

    return media
}

/**
 * Gets the media object from all current media with the given ID or undefined if there isn't one
 */
export const getIconMedia = (allMedia: TypedMap<Media>, identity: HasMedia) => {
    const iconId = getIconId(identity);
    return iconId ? allMedia[iconId] : undefined
}

/**
 * Gets the media object from all current media with the given ID, defaulting it if one isn't defined
 */
export const getIconMediaWithDefaults = (allMedia: TypedMap<Media>, identity: HasMedia) => {
    const iconId = getIconId(identity);
    return getMediaWithDefaults(allMedia, iconId, identity?.type)
}

/**
 * Get the media reference that points at the given media ID
 */
export const getMediaReference = (object: HasMedia, mediaId: string): MediaReference | undefined =>
    object.media.find(i => i.id === mediaId);

/**
 * Returns the ID currently selected media
 */
export const getSelectedMediaId = createSelector(
    [getUiState],
    (uiState) => uiState.editingMediaId ? uiState.editingMediaId : null
);

/**
 * Returns the value of the currently selected media
 */
export const getSelectedMediaOrNull = createSelector(
    [getAllMediaMap, getSelectedMediaId],
    (mediaMap, getSelectedMediaId) => getSelectedMediaId ? mediaMap[getSelectedMediaId] : null
);

/**
 * Returns a map of clip IDs to clip icons
 */
export const getAllIconsMap = createSelector(
    [getAllClipsMap, getAllInformationMap, getAllMediaMap],
    (clips: TypedMap<Clip>, information: TypedMap<Information>, media: TypedMap<Media>) => {
        const icons: TypedMap<Media> = {};

        Object
            .values(clips)
            .forEach((clip: Clip) => {
                const iconId = getIconId(clip);
                if (iconId && media[iconId]) {
                    icons[clip.id] = media[iconId];
                }
            });

        Object
            .values(information)
            .forEach((info: Information) => {
                const iconId = getIconId(info);
                if (iconId && media[iconId]) {
                    icons[info.id] = media[iconId];
                }
            });

        return icons;
    }
);

/**
 * Returns true if the media reference has the given purpose
 */
export const hasPurpose = (media: MediaReference, purpose: MediaPurpose) =>
    media.purposes.find(p => p === purpose) !== undefined;

/**
 * Adds the given purpose to the given media reference
 */
export const addMediaPurpose = (media: MediaReference, purpose: MediaPurpose) =>
    media.purposes.filter(p => p !== purpose).concat(purpose);

/**
 * Removes the given purpose from the given media reference
 */
export const removeMediaPurpose = (media: MediaReference, purpose: MediaPurpose) =>
    media.purposes.filter(p => p !== purpose);


/**
 * Uploads the given file to Cloudinary
 */
export const uploadToCloudinary = (media: File): Promise<CloudinaryUploadResult> => {
    const formData = new FormData();
    formData.append("file", media);
    // formData.append("tags", '{TAGS}'); // Add tags for the images - {Array}
    formData.append("upload_preset", "gohbgmwq"); // Replace the preset name with your own
    formData.append("api_key", "979533118462563"); // Replace API key with your own Cloudinary API key
    formData.append("timestamp", (Date.now() / 1000).toString());

    return fetch(`https://api.cloudinary.com/v1_1/cadve/image/upload`, {
        method: "POST",
        headers: {"X-Requested-With": "XMLHttpRequest"},
        body: formData
    })
        .then(response => response.json())
};

/**
 * Turns an upload result from Cloudinary to a media key
 */
export const buildCloudinaryKey = (uploadResult: CloudinaryUploadResult): string =>
    "v" + uploadResult.version + "/" + uploadResult.public_id;

/**
 * Builds a URL to the given media. This is probably a poor way to handle this, but I'll improve it later
 */
export const buildMediaSource = (m: any) => {
    if(m) {
        let keyType = null;
        let key = null;

        if (m.type === DataType.media) {
            keyType = m.keyType;
            key = m.key
        } else {
            keyType = m.media.keyType;
            key = m.media.key
        }

        if (keyType === MediaKeyType.cloudinary) {
            return "https://res.cloudinary.com/cadve/image/upload/" + key;
        }
        if (keyType === MediaKeyType.internal) {
            return buildInternalIconSourceFromKey(key);
        }
        if (keyType === MediaKeyType.url) {
            return key;
        }
    }

    // TODO return actual unknown default
    return "/public/media/default.png";
}

/**
 * Gets the ID of the icon for the given object, assuming that it has a media object with the correct purpose
 */
export const getIconId = (object: HasMedia) => {
    const media = object.media || []
    const iconMediaRef = media.find(ref => ref.purposes.find(p => p === "icon" as MediaPurpose));
    return iconMediaRef ? iconMediaRef.id : null;
};

/**
 * Builds the icon URL for the given clip, returning the default if one isn't set
 */
export const buildMediaSourceWithDefault = (icon: Media | null, objectType: DataType | null = null) => {
    return icon ? buildMediaSource(icon) : buildMediaSource(getDefaultIconMediaForType(objectType));
};

/**
 * Calculate coordinates to center media at a (0, 0) point
 */
export const centerMedia = (m: Media): CenteredGraphics => centerInBounds(m.width, m.height)

/**
 * Calculate coordinates to center media at a (x, y) point, scaled by the given factor
 */
export const centerMediaAtPoint = (m: Media, x: number, y: number, scale: number): CenteredGraphics =>
    centerInBoundsAtPoint(x, y, m.width * scale, m.height * scale)

/**
 * Collect all media Ids from the set of objects that have media
 */
export const collectMediaIds = (owners: HasMedia[][]): string[] =>
    owners.flatMap(o => o).flatMap(o => o.media ? o.media : []).map(m => m.id)
