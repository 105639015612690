import {Container} from "@pixi/display";
import {Sprite} from "@pixi/sprite";
import {buildMediaSourceWithDefault} from "../../../common/function/MediaFunctions";
import {Media, Pin} from "../../../repository/domain/ApiTypes";
import DraggablePinView from "./DraggablePinView";
import {BoardTags} from "../function/BoardTypes";
import {Graphics} from "@pixi/graphics";
import {Circle, IHitArea} from "pixi.js";
import {Rectangle} from "@pixi/math";


export default class SceneryPinView extends DraggablePinView {

    private readonly border!: Graphics

    constructor(pin: Pin, media: Media) {
        super(new Container(), pin, media, undefined, media, [BoardTags.selected, BoardTags.locked, BoardTags.owned])

        const container = this.view as Container
        const sprite = Sprite.from(buildMediaSourceWithDefault(media))
        container.addChild(sprite)

        this.border = this.buildBorder()
        this.view.hitArea = this.buildHitArea()
        this.adjustForTags()
        container.addChild(this.border)

        container.x = pin.x
        container.y = pin.y
        container.height = media.height
        container.width = media.width
        container.pivot.set(container.width / 2, container.height / 2)
        container.scale.x = pin.scale
        container.scale.y = pin.scale
        container.rotation = this.getRotation()

    }

    public ownedByUser = (userId: string): boolean =>
        false

    private buildBorder = () => {
        return new Graphics()
            .lineStyle(5, 0x4FC4F5, 1, 0.5)
            .drawRect(0, 0, this.media.width, this.media.height)
    }

    protected adjustForTags = () => {
        this.border.visible = this.hasTag(BoardTags.selected)
    }

    buildHitArea = (): IHitArea =>
        new Rectangle(0, 0, this.media.width, this.media.height)

}
