import BaseRepository from "../BaseRepository";
import { DataType, PartialMediaReference } from "../domain/ApiTypes";

/**
 * A service to provide data services for adding media to owning objects
 */
export class MediaOwningDataService {
    private readonly repository: BaseRepository;

    constructor(dataType: DataType) {
        this.repository = new BaseRepository(dataType);
    }

    /**
     * Adds the given media to the given owner
     * @param ownerId the ID of clip that is to be added to
     * @param mediaId the ID of the media to be added
     * @returns a promise with the updated item
     */
    public addMedia(ownerId: string, mediaId : string) {
        return this.repository.addMedia(ownerId, mediaId)
    }

    /**
     * Updates the data on the given media reference
     */
    public updateMediaReference(ownerId: string, mediaId : string, updates: PartialMediaReference) {
        return this.repository.updateMediaReference(ownerId, mediaId, updates)
    }

    /**
     * Removes the given media from the given owner
     * @param ownerId the ID of clip that is to be added to
     * @param mediaId the ID of the media to be added
     * @returns a promise with the updated item
     */
    public removeMedia(ownerId: string, mediaId : string) {
        return this.repository.removeMedia(ownerId, mediaId)
    }

}

export const adventureMediaDataService = new MediaOwningDataService(DataType.adventure)
export const gameMediaDataService = new MediaOwningDataService(DataType.game)
export const playerMediaDataService = new MediaOwningDataService(DataType.player)
export const characterMediaDataService = new MediaOwningDataService(DataType.character)
export const locationMediaDataService = new MediaOwningDataService(DataType.location)
export const itemMediaDataService = new MediaOwningDataService(DataType.item)
export const plotMediaDataService = new MediaOwningDataService(DataType.plot)
export const informationMediaDataService = new MediaOwningDataService(DataType.information)
export const boardMediaDataService = new MediaOwningDataService(DataType.board)
export const userMediaDataService = new MediaOwningDataService(DataType.user)