import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";
import { DataNotLoadedException } from "../../common/exception/Exceptions";
import { Journal, JournalEntry, Session } from "../../repository/domain/ApiTypes";
import { TypedMap } from "../../repository/domain/MapTypes.ds";
import { AppState } from "../../repository/state/AppState";
import { getUiState } from "../../repository/UiFunctions";

/**
 * Returns the journal if a game is running otherwise throws exception
 */
export const getJournal = (state: AppState) : Journal => {
    if(state.journal) return state.journal;
    throw new DataNotLoadedException()
}

/**
 * Returns the journal ID if a game is running otherwise throws exception
 */
export const getJournalId = (state: AppState) : string =>
    getJournal(state).id

/**
 * Returns the map of all sessions
 */
export const getAllSessionsMap = (state: AppState) : TypedMap<Session> => {
    if(state.sessions) {
        return state.sessions
    }
    throw new DataNotLoadedException()
};

/**
 * Returns the value of session currently marked as "current" in the journal
 */
 export const getCurrentJournalSessionId : (state: AppState) => string | undefined = createSelector(
    [ getJournal ],
    (journal) => journal?.currentSessionId
);

/**
 * Returns true if the given journal entry is a discovery entry of the given clip ID
 */
export const isEntryDiscoveryOfClip = (entry: JournalEntry | null, clipId: string) => {
    if(entry && entry.discovery !== null) {
        return entry.discovery && entry.discovery.id === clipId;
    }

    return false;
};

export const getAllEntriesMap = (state: AppState) : TypedMap<JournalEntry> => {
    if(state.entries) {
        return state.entries
    }
    throw new DataNotLoadedException();
};

export const getJournalEntry = (state: AppState, entryId : string) =>
    getAllEntriesMap(state)[entryId];

// export const getCurrentPrimaryTab = createSelector(
//     [ getUiState ],
//     (uiState: UiState) : PrimaryTab => uiState.primaryTab
// );

export const getSessions = createSelector(
    [getAllSessionsMap],
    (allSessions) =>
        Object.values(allSessions).sort((left: Session, right: Session) => left.startDate.localeCompare(right.startDate))
);

export const getSession = createCachedSelector(
    getAllSessionsMap,
    (state: AppState, sessionId: string) => sessionId,
    (allSessions, sessionId) => allSessions[sessionId],
)(
    (_, sessionId) => sessionId
);

export const getSessionEntries = createCachedSelector(
    getSession,
    getAllEntriesMap,
    (session, allEntries) => session.entries.map(e => allEntries[e.id]),
)(
    (_, sessionId) => sessionId
);

/**
 * Returns the ID currently selected journal entry
 */
export const getSelectedEntryIdOrNull : (state: AppState) => string | null = createSelector(
    [ getUiState ],
    (uiState) => uiState.editingEntryId ? uiState.editingEntryId : null
);

/**
 * Returns true if a journal entry is being edited
 */
export const isEntrySelected : (state: AppState) => boolean = createSelector(
    [ getSelectedEntryIdOrNull ],
    (selectedEntryId) => selectedEntryId !== null
);

/**
 * Returns the value of the currently selected journal entry
 */
export const getSelectedEntryOrNull : (state: AppState) => JournalEntry | null = createSelector(
    [ getAllEntriesMap, getSelectedEntryIdOrNull ],
    (entryMap, selectedEntryId) => selectedEntryId ? entryMap[selectedEntryId] : null
);

// TODO delete
// /**
//  * Returns the discovery entry, if it exists, for the currently selected clip - null otherwise
//  */
// export const getSelectedClipDiscoveryEntry : (state: AppState) => DiscoveryEntry = createSelector(
//     [ getAllEntriesMap, getEditingClipId],
//     (entryMap, selectedClipId) => {
//         if(entryMap && selectedClipId) {
//             const filtered = Object.values(entryMap)
//                 .filter(entry => entry.type === DataType.discovery && (entry as DiscoveryEntry).discovery.id === selectedClipId);
//
//             if(filtered && filtered.length > 0) {
//                 return filtered[0] as DiscoveryEntry
//             }
//         }
//         else {
//             return null;
//         }
//     }
// );

/**
 * Returns the clip discovery entry for the given clip ID. If it doesn't exist (because the clip hasn't been discovered yet),
 * return null.
 */
export const getClipDiscoveryEntry = createCachedSelector(
    getAllEntriesMap,
    (state: AppState, clipId: string) => clipId,
    (entryMap, clipId) => {
        if(entryMap && clipId) {
            const filtered = Object.values(entryMap)
                .filter(entry => isEntryDiscoveryOfClip(entry, clipId));

            if(filtered && filtered.length > 0) {
                return filtered[0]
            }
        }
        else {
            return null;
        }
    },
)(
    (_, clipId) => clipId
);

/**
 * Returns the learning entry, if it exists, for the given information item - null otherwise
 */
export const getInformationLearningEntry = createCachedSelector(
    getAllEntriesMap,
    (state: AppState, informationId: string) => informationId,
    (entryMap, informationId) => {
        const filtered = Object.values(entryMap)
            .filter(entry => entry.information !== null && entry.information.id === informationId);

        if(filtered && filtered.length > 0) {
            return filtered[0]
        }

        return null;
    }
)(
    (_, informationId) => informationId
);

