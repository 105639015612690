import BaseRepository from "../BaseRepository";
import {CHILDREN_API_KEY, CURRENT_USER_API_KEY} from "../domain/ApiConstants";
import {Changes, DataType, User} from "../domain/ApiTypes";

/**
 * A service to provide data services for getting user data
 */
export class UserDataService {
    private readonly userRepository: BaseRepository;

    constructor() {
        this.userRepository = new BaseRepository(DataType.user)
    }

    /**
     * Retrieves the current user information
     */
    public findCurrentUser = () =>
        this.userRepository.findSingleItem(CURRENT_USER_API_KEY);

    /**
     * Retrieves the current user's children data
     */
    public findCurrentUserChildren = () =>
        this.userRepository.findSubObjects(CURRENT_USER_API_KEY, CHILDREN_API_KEY);

    public finishLogin = (jwt: string) =>
        this.userRepository.startLogin

    public removeOwnedObject = (ownedObjectId: string) : Promise <Changes> =>
        this.userRepository.deleteSubObjectWithReference(CURRENT_USER_API_KEY, "owns", ownedObjectId)

    /**
     * Changes the owner of the given object to the given user
     */
    public changeOwner = (objectId: string, newUserId: string) : Promise<null> => {
        return this.userRepository.addNewSubObjectWithReferenceAndBody(CURRENT_USER_API_KEY, "changeOwner",  objectId,{newUserId})
    }

    /**
     * Saves the update to the adventure with the given ID
     */
    public updateFields = (userId: string, updated: Partial<User>): Promise<User> => {
        return this.userRepository.updateItem(userId, updated)
    };
}

export const userDataService = new UserDataService()