import loadable from '@loadable/component'
import * as React from "react";
import * as ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {appReducer} from "./repository/AppReducer";
import {BrowserRouter} from 'react-router-dom';
import {configureStore} from "@reduxjs/toolkit";
import './index.css';

const App = loadable(() => import('./feature/main/App'));

const store = configureStore({
    reducer: appReducer,
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
  document.getElementById("root") as HTMLElement
);
