import {TypedMap} from "../domain/MapTypes.ds";
import {adventureDataService} from "./AdventureDataService";
import {authService} from "./AuthService";
import {boardDataService} from "./BoardDataService";
import {clipDataService} from "./ClipDataService";
import {clipProxyDataService} from "./ClipProxyDataService";
import {configurationService} from "./ConfigurationService";
import {gameDataService, GameDataService} from "./GameDataService";
import {informationDataService} from "./InformationDataService";
import {journalDataService} from "./JournalDataService";
import {journalEntryDataService} from "./JournalEntryDataService";
import {mediaDataService} from "./MediaDataService";
import {pinDataService} from "./PinDataService";
import {playerDataService} from "./PlayerDataService";
import {sceneDataService} from "./SceneDataService";
import {searchDataService} from "./SearchDataService";
import {sessionDataService} from "./SessionDataService";
import {stageDataService} from "./StageDataService";
import {trackerDataService} from "./TrackerDataService";
import {userDataService} from "./UserDataService";
import {viewDataService} from "./ViewDataService";

export const globalServices: TypedMap<any> = {
    adventureDataService: adventureDataService,
    authService: authService,
    boardDataService: boardDataService,
    clipDataService: clipDataService,
    clipProxyDataService: clipProxyDataService,
    configurationService: configurationService,
    gameDataService: gameDataService,
    informationDataService: informationDataService,
    journalDataService: journalDataService,
    journalEntryDataService: journalEntryDataService,
    mediaDataService: mediaDataService,
    pinDataService: pinDataService,
    playerDataService: playerDataService,
    sceneDataService: sceneDataService,
    searchDataService: searchDataService,
    sessionDataService: sessionDataService,
    stageDataService: stageDataService,
    trackerDataService: trackerDataService,
    userDataService: userDataService,
    viewDataService: viewDataService,
}
