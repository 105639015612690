import { Reducer } from "../../../repository/AppReducer";
import { AppState } from "../../../repository/state/AppState";


interface ActionParameters {
    type: string,
    clipId: string
}

/**
 * Changes the state to have the given clip (by ID) selected
 */
export class SelectClipAction implements Reducer<ActionParameters> {
    public type = 'SelectClipAction';

    public build = (clipId: string) : ActionParameters => ({
        type: this.type,
        clipId
    });

    public reduce = (state: AppState, action: ActionParameters) : AppState => {
        return {
            ...state,
            ui: {
                ...state.ui,
                selectedClipId: action.clipId,
            }
        }
    };
}

const selectClipAction = new SelectClipAction();

export default selectClipAction;