import BaseRepository from "../BaseRepository";
import {Changes, DataType, PartialJournalEntry, Session} from "../domain/ApiTypes";

/**
 * A service to provide data services for sessions
 */
export class SessionDataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository(DataType.session);
    }

    /**
     * Finds the session with the given ID 
     */
    public find = (sessionId: string) =>
         this.repository.findSingleItem(sessionId)
      
  
    /**
     * Saves the update to the session with the given ID
     */
    public updateItemFields = (sessionId: string, updated: Partial<Session>): Promise<Session> => {
        return this.repository.updateItem(sessionId, updated)
    };

    /**
     * Creates a new writing entry in the given session
     */
    public createWritingEntry = (sessionId: string, newEntry: PartialJournalEntry) : Promise<Changes> =>
        this.repository.addNewSubObjectWithBody(
            sessionId,
            DataType.writing,
            newEntry);

}

export const sessionDataService = new SessionDataService()