import {Reducer} from "../../../repository/AppReducer";
import {AppState} from "../../../repository/state/AppState";

interface ActionParameters {
    type: string
    playerId: string
}
// TODO remove, not used anymore

/**
 * In a game being played in shared mode, switch the current player to the given player
 */
export class SwitchCurrentPlayer implements Reducer<ActionParameters> {
    public type = 'SwitchCurrentPlayer';

    public toPlayer = (playerId: string) => ({
        type: this.type,
        playerId,
    });

    public reduce = (state: AppState, action: ActionParameters) : AppState => {
        return {
            ...state,
            player: state.participantPlayers[action.playerId]
        } as AppState
    };

}

const switchCurrentPlayer = new SwitchCurrentPlayer();
export default switchCurrentPlayer;