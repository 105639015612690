import refetchStoredObjects from './../common/action/RefetchStoredObjects';
import createObject from "../common/action/CreateObject";
import updateObject from '../common/action/UpdateObject';
import updateStoredObjects from "../common/action/UpdateStoredObjects";
import userLogout from "../common/action/UserLogout";
import startEditingAdventureAction from "../feature/adventure/action/StartEditingAdventureAction";
import deleteClipAction from "../feature/clip/action/DeleteClipAction";
import selectClipAction from "../feature/clip/action/SelectClipAction";
import selectClipAndInformationAction from "../feature/clip/action/SelectClipAndInformationAction";
import selectNoClipAction from "../feature/clip/action/SelectNoClipAction";
import startGameAction from "../feature/game/action/StartGameAction";
import clearSelectedInformationAction from "../feature/information/action/ClearSelectedInformationAction";
import clearSelectedEntryAction from "../feature/journal/action/ClearSelectedEntryAction";
import selectSession from "../feature/journal/action/SelectSession";
import loadLobbyAction from "../feature/lobby/LoadLobbyAction";
import resetStateAction from "../feature/lobby/ResetStateAction";
import playerStandalone from "../feature/player/action/PlayerStandalone";
import {AppState, initialState} from "./state/AppState";
import updateProgressDialog from "../common/action/UpdateProgressDialog";
import prepareForLobby from "../common/action/PrepareForLobby";
import startPlayingGame from "../feature/player/action/StartPlayingGame";
import loadSession from "../feature/journal/action/LoadSession";
import switchCurrentPlayer from "../feature/player/action/SwitchCurrentPlayer";
import playerInGame from "../feature/player/action/PlayerInGame";
import {LoadUserAction} from "../feature/lobby/LoadUserAction";
import {StateAction} from "./StateAction";
import {TypedMap} from "./domain/MapTypes.ds";
import {CreateBoardForOwner} from "../feature/board/action/CreateBoardForOwner";
import {MarkClipDiscovery} from "../feature/clip/action/MarkClipDiscovery";
import UpdateClip from "../feature/clip/action/UpdateClip";

export interface Reducer<T> {
    type: string;
    reduce: (state: AppState, action: T) => AppState;
}

const reducers: Array<Reducer<any>> = [
    clearSelectedEntryAction,
    clearSelectedInformationAction,
    deleteClipAction,

    createObject,
    updateObject,
    selectSession,

    startEditingAdventureAction,
    startGameAction,
    resetStateAction,
    playerStandalone,
    startPlayingGame,
    switchCurrentPlayer,
    playerInGame,
    playerStandalone,

    selectClipAction,
    selectClipAndInformationAction,
    selectNoClipAction,

    loadLobbyAction,
    prepareForLobby,
    loadSession,

    updateProgressDialog,
    updateStoredObjects,
    refetchStoredObjects,
    userLogout,
];

const actionClasses: Array<{ new (): StateAction }> = [
    LoadUserAction,
    CreateBoardForOwner,
    MarkClipDiscovery,
    UpdateClip,
]

const actionClassesByType: TypedMap<{ new (): StateAction }> =
    Object.assign({}, ...actionClasses.map((x) => ({[new x().type()]: x})))


/**
 * Our customized reducer. Matches the Action's `type` parameter against the list of registered Reducers/StateActions
 * and runs the appropriate one. If it matches a StateAction, the appropriate method is called for that API.
 */
export const appReducer = (state: AppState = initialState, action: any) => {
    for (const reducer of reducers) {
        if(reducer.type === action.type) {
            return reducer.reduce(state, action);
        }
    }

    const actionClass = actionClassesByType[action.type]
    if(actionClass) {
        const actionObject = new actionClass()
        return actionObject.reduceActionToState(state, action)
    }

    return state
}
