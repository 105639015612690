import { Reducer } from "../../../repository/AppReducer";
import { AppState } from "../../../repository/state/AppState";


interface ActionParameters {
    type: string
}

/**
 * Updates the state to have no clip selected
 */
export class SelectNoClipAction implements Reducer<ActionParameters> {
    public type = 'SELECT_NO_CLIP';

    public build = () : ActionParameters => ({
        type: this.type,
    });

    public reduce = (state: AppState, action: ActionParameters) : AppState => {
        return {
            ...state,
            ui: {
                ...state.ui,
                selectedClipId: null
            },
        }
    };
}

const selectNoClipAction = new SelectNoClipAction();

export default selectNoClipAction;