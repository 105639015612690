import createCachedSelector from "re-reselect";
import {createSelector} from "reselect";
import {getDefaultIconMediaForType, getLastDitchDefaultMedia} from "../../../assets/InternalMedia";
import {DataNotLoadedException} from "../../../common/exception/Exceptions";
import {getAdventure} from "../../../common/function/AdventureFunctions";
import {
    Adventure,
    Board,
    Changes,
    DataType,
    Identity,
    PartialPin,
    Pin,
    PinType,
    PolygonGraphic,
    RegularPolygonGraphic
} from "../../../repository/domain/ApiTypes";
import {TypedMap} from "../../../repository/domain/MapTypes.ds";
import {AppState} from "../../../repository/state/AppState";
import {PinView} from "../view/PinView";
import ActorPinView from "../view/ActorPinView";
import {BoardTags, PinChangeAction, PinChangedHandler} from "./BoardTypes";
import BackgroundPinView from "../view/BackgroundPinView";
import SceneryPinView from "../view/SceneryPinView";
import {BoardPanelOptions, PixiScreen} from "./PixiScreen";
import {pinDataService} from "../../../repository/service/PinDataService";
import {isClip, isClipProxy} from "../../clip/ClipFunctions";
import {isMedia} from "../../../common/function/MediaFunctions";
import {isPlayer} from "../../player/PlayerFunctions";
import {Dispatch, SetStateAction} from "react";
import {
    OwningBoardDataService,
    characterBoardDataService,
    locationBoardDataService,
    itemBoardDataService,
    plotBoardDataService,
    informationBoardDataService,
} from "../../../repository/service/OwningBoardDataService";
import {isStringEmpty} from "../../../common/function/StringFunctions";
import {FederatedPointerEvent} from "@pixi/events";
import {throttle} from "throttle-typescript";

export const isBoard = (identity: Identity) => identity.type === DataType.board
export const filterBoard = (identities: Identity[]) => identities.filter(c => isBoard(c)).map(c => c as Board)

/**
 * Returns the map of all boards in the current adventure
 */
export const getAllBoardsMap = (state: AppState): TypedMap<Board> => {
    if (state.boards) return state.boards;
    throw new DataNotLoadedException();
};

/**
 * Returns the map of all pins currently loaded
 */
export const getAllPinsMap = (state: AppState): TypedMap<Pin> => {
    if (state.pins) return state.pins;
    throw new DataNotLoadedException();
};

/**
 * Gets a single board, by ID
 */
export const getBoard = createCachedSelector(
    getAllBoardsMap,
    (state: AppState, boardId: string) => boardId,
    (allBoards, boardId) => allBoards[boardId],
)(
    (_, boardId) => boardId
);

/**
 * Gets the pins on the board with the given ID
 */
export const getPinsOnBoard = createCachedSelector(
    getAllBoardsMap,
    (state: AppState, boardId: string) => boardId,
    (allBoards, boardId) => allBoards[boardId]?.pins,
)(
    (_, boardId) => boardId
)

/**
 * Gets the pin with the given ID
 */
export const getPin = createCachedSelector(
    getAllPinsMap,
    (state: AppState, pinId: string) => pinId,
    (allPins, pinId) => allPins[pinId],
)(
    (_, pinId) => pinId
)

/**
 * Get the overview board for the adventure
 */
export const getOverviewBoard = createSelector(
    [getAdventure, getAllBoardsMap],
    (adventure: Adventure, allBoards: TypedMap<Board>) => allBoards[adventure.overviewBoard.id]
);

export const updatePin = (current: Pin, updates: PartialPin): Pin => {
    return {
        ...current,
        x: updates.x ? updates.x : current.x,
        y: updates.y ? updates.y : current.y,
        alias: updates.alias ? updates.alias : current.alias,
        pinType: updates.pinType ? updates.pinType : current.pinType,
        scale: updates.scale ? updates.scale : current.scale,
        tags: updates.tags ? updates.tags : current.tags,
    }
}

/**
 * Gets the pinned object for the given pin, defaulting where necessary
 */
export const getPinnedObject = (pin?: Pin) =>
    (pin?.clip ? pin.clip : (pin?.proxy ? pin.clip : (pin?.player ? pin.player : pin?.media))) ?? getLastDitchDefaultMedia()

/**
 * Gets the media for the given pin, defaulting where necessary
 */
export const getPinMedia = (pin?: Pin) =>
    pin?.media ?? getDefaultIconMediaForType(getPinnedObject(pin).type) ?? getLastDitchDefaultMedia()


/**
 * Gets the name for the given pin, assuming the given lists of types of pinned objects
 */
 export const getPinName = (pin: Pin | null | undefined, hideRealName: boolean = false) => {
    if(!pin) return "Unknown"
    let name = null;

    if(pin.alias) {
        name = pin.alias
    }
    else if (pin.clip && (isClip(pin.pinnedObject) || isClipProxy(pin.pinnedObject))) {
        if(hideRealName && !isStringEmpty(pin.clip.descriptiveName)) {
            name = pin.clip.descriptiveName
        }
        else {
            name = pin.clip.name
        }
    }
    else if (pin.media && isMedia(pin.pinnedObject)) {
        name = pin.media.name
    }
    else if (pin.player && isPlayer(pin.pinnedObject)) {
        name = pin.player.name
    }
    else {
        if(pin.pinType === PinType.background) {
            name = "background"
        }
        else if(pin.pinType === PinType.scenery) {
            name = "scenery"
        }
    }

    if (!name) {
        name = "Unspecified"
    }

    return name
}

/**
 * Converts the pin's type to a string with an initial capital letter
 */
export const getPinTypeName = (pinType: PinType) =>
    `${pinType.substr(0, 1).toUpperCase()}${pinType.substring(1).toLowerCase()}`


/**
 * Builds a PinView, using the global state for media, clips, maps, and participants
 */
export const buildPinView = (pin: Pin, options: BoardPanelOptions) : PinView => {
    const pinnedObject = getPinnedObject(pin)
    const media = getPinMedia(pin)
    const name = getPinName(pin)

    //console.log(`Build pinview for ${pinView?.getPin().id} - ${pinView?.getName()}`)
    let pinView: PinView | undefined

    if(pin.pinType === PinType.actor) {
        pinView = new ActorPinView(pin, pinnedObject, pin.proxy, media, options.hideRealNames)
        if(pin.player) {
            pinView.addTag(BoardTags.player)
        }
    }
    else if(pin.pinType === PinType.background) {
        pinView = new BackgroundPinView(pin, media)
    }
    else if(pin.pinType === PinType.scenery) {
        pinView = new SceneryPinView(pin, media)
    }
    else {
        pinView = new ActorPinView(pin, pinnedObject, undefined, getLastDitchDefaultMedia(), options.hideRealNames)
    }

    // TODO Once the "selected" tag is saved, can bring this back
    // const selected = false
    // if(pinView) {
    //     if(selected) pinView.addTag(BoardTags.selected)
    //     else pinView.removeTag(BoardTags.selected)
    // }

    //console.log(`Build pinview for ${pinView?.getPin().id} - ${pinView?.getName()}`)
    return pinView
}

/**
 * Updates the PixiScreen with the board's settings
 */
export const updateScreen = (screen: PixiScreen, board: Board | undefined, options: BoardPanelOptions, updatedPins: Pin[], deletedPins: Pin[], veils: RegularPolygonGraphic[], areas: PolygonGraphic[]) => {
    if(screen) {
        screen.addVeils(veils)
        screen.addRevealAreas(areas)
        const pinViews = updatedPins.map(p => {
            const newPinView = buildPinView(p, options)
            const currentPinView = screen.getPin(p.id)
            if(currentPinView) {
                currentPinView.getViewTags().forEach(t => newPinView.addTag(t))
            }
            return newPinView
        })

        // console.log(`New pinviews ${pinViews}`)

        screen.addPinViews(pinViews)
        deletedPins.forEach(p => screen.removePinView(p.id))

        if(board) {
            screen.setSceneryLock(board.lockBackground)
            screen.setShowGrid(board.showGrid)
            screen.setShowVeils(board.showVeils)
            screen.setRevealAreaActive(board.hideBackground)
            // console.log("Updated board config")
        }
    }
}

/**
 * Returns the board object if the given changes includes it, otherwise returns undefined
 */
export const changeIncludesBoard = (changes: Changes, boardId: string) : Board | undefined => {
    if (changes.primary?.id === boardId) return changes.primary as Board
    else return changes.others?.find(o => o.id === boardId) as Board
}

/**
 * Takes any update action necessary to update the given PixiScreen on the board with the given ID,
 * based on the changes received
 */
export const updateScreenFromChanges = (pixiScreen: PixiScreen, boardId: string, changes: Changes, options: BoardPanelOptions) => {
    // console.log(`Receiving - Pixiscreen ${pixiScreen}`)
    if(!pixiScreen) return

    // console.log(`Received changes ${JSON.stringify(changes)}`)
    let updates: Pin[] = []
    let deletes: Pin[] = []
    const board = changeIncludesBoard(changes, boardId)
    // console.log(`Board found ${board?.id}`)

    // If board is included, the change is an addition or deletion to the board
    if(board) {
        if(changes.primary?.id && changes.primary?.type === DataType.pin) {
            updates.push(changes.primary as Pin)
        }
        if(changes.others) {
            changes.others.forEach(o => {
                if(o?.id && o?.type === DataType.pin) {
                    updates.push(o as Pin)
                }
            })
        }
    }
    // Otherwise, only pins are changing
    else {
        if(changes.primary?.id) {
            pixiScreen.getAffectedPins(changes.primary.id).forEach(p => updates.push(p))
        }
        if(changes.others) {
            changes.others.forEach(o => {
                pixiScreen.getAffectedPins(o.id).forEach(p => updates.push(p))
            })
        }
    }

    if(changes.deletions) {
        changes.deletions.forEach(o => {
            if(o.type === DataType.pin) deletes.push(o as Pin)
        })
    }

    // console.log(`Final updates ${JSON.stringify(updates)}`)
    // console.log(`Final deletes ${JSON.stringify(deletes)}`)

    Promise.all(updates.map(p => pinDataService.find(p.id)))
        .then(reloadedPins =>
            updateScreen(pixiScreen, board, options, reloadedPins, deletes, [], [])
        )

    // console.log(`Received ${message?.pin?.id}: ${message?.name} (${message?.pin?.x}, ${message?.pin?.y})`)
}

/**
 * Returns true if the two pin views are different, indicating that an update should be processed
 * TODO this isn't working with media changes, as the actual Clip's last updated date isn't updated when just a media reference changes
 */
export const detailsDiffer = (original: PinView, updated: PinView) => {
    return (
        updated.getPin().lastUpdatedAt > original.getPin().lastUpdatedAt ||
        updated.getPinnedObject().lastUpdatedAt > original.getPinnedObject().lastUpdatedAt ||
        updated.getMedia().lastUpdatedAt > original.getMedia().lastUpdatedAt
    )
}

/**
 * On a Pin changed event, update the selected Pin if the event indicates that
 */
export const setSelectedFromPinChange = (pin: PinView, action: PinChangeAction, setSelectedPin: Dispatch<SetStateAction<PinView | undefined>>) => {
    if(pin.hasTag(BoardTags.selected)) {
        if(action === PinChangeAction.update) setSelectedPin(currentPin => pin)
        else setSelectedPin(undefined)
    }
    else {
        setSelectedPin((currentSelection: PinView | undefined) => {
            if(currentSelection && currentSelection.getId() === pin.getId()) {
                return undefined
            }
            else {
                return currentSelection
            }
        })
    }
}

/**
 * Builds a handler that calls setSelectedFromPinChange based on a Pin changed event
 */
export const buildPinSelectionHandler = (setSelectedPin: Dispatch<SetStateAction<PinView | undefined>>) : PinChangedHandler =>
    (pin: PinView, action: PinChangeAction) => setSelectedFromPinChange(pin, action, setSelectedPin)

/**
 * On a Pin changed event, update the background Pin if the event indicates that
 */
export const setBackgroundFromPinChange = (pin: PinView, action: PinChangeAction, setBackgroundPin: (p: PinView | undefined) => void) => {
    if(pin.getPin().pinType === PinType.background) setBackgroundPin(action === PinChangeAction.delete ? undefined : pin)
}


/**
 * Sorts pin views appropriately for listing on the player's screen
 */
export const sortPlayersPinViews = (a: PinView, b: PinView): number => {
    if(isPlayer(a.getPinnedObject())) return -1
    else if(isPlayer(b.getPinnedObject())) return 1
    else return a.getName().localeCompare(b.getName())
}

/**
 * Sorts pins appropriately for listing on the player's screen
 */
export const sortPlayersPins = (a: Pin, b: Pin): number => {
    if(isPlayer(a.pinnedObject)) return -1
    else if(isPlayer(b.pinnedObject)) return 1
    else return getPinName(a).localeCompare(getPinName(b))
}

/**
 * Returns the appropriate data service that owns Boards, given the data type passed in
 */
export const getBoardOwningDataService = (ownerType: DataType): OwningBoardDataService => {
    if (ownerType === DataType.character) return characterBoardDataService;
    if (ownerType === DataType.location) return locationBoardDataService;
    if (ownerType === DataType.item) return itemBoardDataService;
    if (ownerType === DataType.plot) return plotBoardDataService;
    if (ownerType === DataType.information) return informationBoardDataService;

    throw new DataNotLoadedException()
}

/**
 * Throttles the given Pixi interaction event with the given limit, or 25
 */
export const throttleEvent = (handler: (e: FederatedPointerEvent) => void, limit: number | undefined = 25) : (e: FederatedPointerEvent) => void =>
    throttle(handler, limit)


/**
 * A simple event handler that throws away the given event
 */
export const discardEvent = (event: any) => {
    const e = event as FederatedPointerEvent
    e.stopPropagation()
}
