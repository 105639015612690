import {Dispatch} from "redux";
import {Reducer} from "../../../repository/AppReducer";
import {Identity} from "../../../repository/domain/ApiTypes";
import {AppState, clearStateForLoading} from "../../../repository/state/AppState";
import {AppMode} from "../../../repository/state/UiState";
import {updateStateWithObjects} from '../../../common/function/ActionFunctions';
import {viewDataService} from "../../../repository/service/ViewDataService";

interface ActionParameters {
    type: string,
    items: Identity[],
    mode: AppMode,
}

/**
 * Loads the data for the selected Player and Game to start playing a game by that player
 */
export class StartPlayingGame implements Reducer<ActionParameters> {
    public type = 'StartPlayingGame'

    public singlePlayer = (playerId: string, gameId: string) => (dispatch: Dispatch<any>, getState: () => AppState) => {
        //dispatch(startStoreOperation());   // TODO put this back in
        viewDataService.fetchDataForPlayGame(gameId, playerId)
            .then( items => dispatch(this.buildReducer(items, AppMode.PLAY_GAME)))
    }

    public shared = (gameId: string) => (dispatch: Dispatch<any>, getState: () => AppState) => {
        //dispatch(startStoreOperation());   // TODO put this back in
        viewDataService.fetchDataForPlayGameShared(gameId)
            .then( items => dispatch(this.buildReducer(items, AppMode.PLAY_GAME_SHARED)))
    }

    public buildReducer = (items: Identity[], mode: AppMode) : ActionParameters => ({
        type: this.type,
        items,
        mode
    });

    public reduce = (state: AppState, action: ActionParameters) : AppState => {
        const newState = clearStateForLoading(state)
        return updateStateWithObjects(newState, action.items, {...state.ui, mode: action.mode})
    }

}

const startPlayingGame = new StartPlayingGame();
export default startPlayingGame;