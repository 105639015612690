import {Reducer} from "../../../repository/AppReducer";
import {AppState} from "../../../repository/state/AppState";

interface ActionParameters {
    type: string,
}

/**
 * Clears the currently selected information
 */
export class ClearSelectedInformationAction implements Reducer<ActionParameters> {
    public type = 'ClearSelectedInformationAction';

    public build = () : ActionParameters => {
        // console.log("building clear selected information");
        return ({
            type: this.type
        });
    };

    public reduce = (state: AppState, action: ActionParameters): AppState => {

        // console.log("Reducing clear selected information");
        return ({
            ...state,
            ui: {
                ...state.ui,
                editingInformationId: null
            }
        });
    }

}

const clearSelectedInformationAction = new ClearSelectedInformationAction();
export default clearSelectedInformationAction;
