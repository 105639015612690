import {Reducer} from "../../../repository/AppReducer";
import {AppState} from "../../../repository/state/AppState";

interface ActionParameters {
    type: string,
}

/**
 * Clears the currently selected journal entry
 */
export class ClearSelectedEntryAction implements Reducer<ActionParameters> {
    public type = 'ClearSelectedEntryAction';

    public build = () : ActionParameters => {
        return ({
            type: this.type
        });
    };

    public reduce = (state: AppState, action: ActionParameters): AppState => {
        return ({
            ...state,
            ui: {
                ...state.ui,
                editingEntryId: null
            }
        });
    }

}

const clearSelectedEntryAction = new ClearSelectedEntryAction();
export default clearSelectedEntryAction;
