import {DataType, Media, MediaKeyType, MediaKind} from "../repository/domain/ApiTypes";
import {TypedMap} from "../repository/domain/MapTypes.ds";
// import defaultCharacterIcon from "../assets/default-character.png"
// import defaultLocationIcon from "../assets/default-location.png"
// import defaultPlotIcon from "../assets/default-plot.png"
import defaultCharacterIcon from "../assets/character-hb.png"
import defaultLocationIcon from "../assets/location-hb.png"
import defaultItemIcon from "../assets/default-item.png"
import defaultPlotIcon from "../assets/plot-hb.png"
import questionMarkIcon from "../assets/question-mark.svg"
import knowsToggleOn from "../assets/knows-toggle-on.png"
import knowsToggleOff from "../assets/knows-toggle-off.png"
import aboutToggleOn from "../assets/about-toggle-on.png"
import aboutToggleOff from "../assets/about-toggle-off.png"

const internalMediaSources: TypedMap<any> = {
    "default-character": defaultCharacterIcon,
    "default-location": defaultLocationIcon,
    "default-item": defaultItemIcon,
    "default-plot": defaultPlotIcon,
    "default-unknown": questionMarkIcon,
};

const internalMedia: TypedMap<Media> = {}
internalMedia[DataType.character] = {
    id: "default-character-media",
    type: DataType.media,
    description: "The default icon for a character",
    kind: MediaKind.image,
    keyType: MediaKeyType.internal,
    key: 'default-character',
    height: 456,
    width: 456
} as Media
internalMedia[DataType.location] = {
    id: "default-location-media",
    type: DataType.media,
    description: 'The default icon for a location',
    kind: MediaKind.image,
    keyType: MediaKeyType.internal,
    key: 'default-location',
    height: 800,
    width: 800
} as Media
internalMedia[DataType.item] = {
    id: "default-item-media",
    type: DataType.media,
    description: 'The default icon for an item',
    kind: MediaKind.image,
    keyType: MediaKeyType.internal,
    key: 'default-item',
    height: 100,
    width: 100
} as Media
internalMedia[DataType.plot] = {
    id: "default-plot-media",
    type: DataType.media,
    description: 'The default icon for a plot',
    kind: MediaKind.image,
    keyType: MediaKeyType.internal,
    key: 'default-plot',
    height: 152,
    width: 152
} as Media
internalMedia[DataType.unspecified] = {
    id: "default-unknown-media",
    type: DataType.media,
    description: 'The default icon for an unspecified item',
    kind: MediaKind.image,
    keyType: MediaKeyType.internal,
    key: 'default-unknown',
    height: 100,
    width: 100
} as Media


export const aboutToggleIconSource = (isOn: boolean) =>
    isOn ? aboutToggleOn : aboutToggleOff;

export const knowsToggleIconSource = (isOn: boolean) =>
    isOn ? knowsToggleOn : knowsToggleOff;

/**
 * Builds the default icon URL for the given clip type
 */
export const buildInternalIconSourceFromKey = (key: string | null) => {
    const media = key && internalMediaSources[key]
    return media ? media : questionMarkIcon
};

/**
 * Returns the internal Media object with the given ID, or undefined if it doesn't exist
 */
export const getInternalMedia = (id: string) =>
    internalMedia[id]

/**
 * Returns the media to use when all other media searches have been exhausted
 */
export const getLastDitchDefaultMedia = () =>
    internalMedia[DataType.unspecified]

/**
 * Builds the default icon URL for the given clip type
 */
export const getDefaultIconMediaForType = (objectType: DataType | null) : Media => {
    let defaultMedia : Media | undefined = undefined

    if(objectType) {
        defaultMedia = internalMedia[objectType]
    }

    if(!defaultMedia) {
        defaultMedia = internalMedia[DataType.unspecified]
    }

    return defaultMedia
};
