import {PartialClip} from "../../../repository/domain/ApiTypes";
import {clipDataService} from "../../../repository/service/ClipDataService";
import {StateAction} from "../../../repository/StateAction";

/**
 * Updates the data in a Clip
 */
export default class UpdateClip extends StateAction {
    public type = () => 'UpdateClip';

    public update = (clipId: string, updates: PartialClip) => {
        // dispatch(startStoreOperation());   // TODO put this back in

        clipDataService.updateItemFields(clipId, updates)
            .then(this.queueSimpleSave)

    }
}
