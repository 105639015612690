import {Container, DisplayObject} from "@pixi/display";
import {Graphics} from "@pixi/graphics";
import {Sprite} from "@pixi/sprite";
import {Text, TextStyle} from "@pixi/text";
import {buildMediaSourceWithDefault} from "../../../common/function/MediaFunctions";
import {ClipProxy, Media, NamedIdentity, Pin} from "../../../repository/domain/ApiTypes";
import {BoardTags} from '../function/BoardTypes';
import DraggablePinView from "./DraggablePinView";
import {getPinName} from "../function/BoardFunctions";
import {Circle, IHitArea} from "pixi.js";


export default class ActorPinView extends DraggablePinView {

    private standardIconSize = 100
    private standardTextSize = this.standardIconSize * 0.25

    private haloGap = 4
    private haloThickness = 5
    private haloSize = this.haloGap + this.haloThickness

    private iconRadius!: number
    private iconCenterX!: number
    private iconCenterY!: number
    private iconSize!: number

    private borderObject!: Graphics
    private haloObject!: Graphics
    private overlayObject!: Graphics
    private labelObject!: DisplayObject

    private hideRealName: boolean

    constructor(pin: Pin, pinnedObject: NamedIdentity, proxy: ClipProxy | undefined, media: Media, hideRealName: boolean) {
        super(new Container(), pin, pinnedObject, proxy, media, [BoardTags.selected, BoardTags.friendly, BoardTags.owned, BoardTags.knockedOut])
        this.hideRealName = hideRealName
        this.rebuildView()
        this.view.x = pin.x
        this.view.y = pin.y
        //container.addChild(drawCrosshairs(new Point(this.iconCenterX, this.iconCenterY), 0x0314FC, 5))
    }


    public getName = () : string => {
        return getPinName(this.pin, this.hideRealName)
    }

    /**
     * Sets the alias of the pin, adjusting the view
     */
    public setAlias = (alias: string) => {
        if(alias !== this.getName()) {
            this.pin.alias = alias
            const container = this.view as Container

            const index = container.getChildIndex(this.labelObject)
            container.removeChildAt(index)
            this.labelObject = container.addChildAt(this.buildLabel(), index)
        }
    }

    setScale(scale: number): void {
        this.pin.scale = scale
        this.rebuildView()
    }

    // TODO clean up so we aren't adding and removing graphics every tick, only when necessary
    protected adjustForTags = () => {
        this.drawBorder()
        this.drawHalo()
        this.drawOverlay()
    }

    private drawKnockedOut = (g: Graphics) => {
        g
            .lineStyle(this.haloThickness * 3, 0xff0000, 0.5)
            .moveTo(this.iconCenterX - this.iconRadius, this.iconCenterY - this.iconRadius)
            .lineTo(this.iconCenterX + this.iconRadius, this.iconCenterY + this.iconRadius)
            .moveTo(this.iconCenterX + this.iconRadius, this.iconCenterY - this.iconRadius)
            .lineTo(this.iconCenterX - this.iconRadius, this.iconCenterY + this.iconRadius)
    }

    private drawBorder = () => {
        const borderColor = this.hasTag(BoardTags.selected) ? 0x4FC4F5 : 0x000000

        this.borderObject
            .clear()
            .lineStyle(5, borderColor, 1, 0.5)
            .drawCircle(this.iconCenterX, this.iconCenterY, this.iconRadius)
    }

    private drawHalo = () => {
        if(this.hasTag(BoardTags.player) || this.hasTag(BoardTags.friendly)) {
            this.haloObject
                .clear()
                .lineStyle(this.haloThickness, 0xCD70DE, 1, 0.5)
                .drawCircle(this.iconCenterX, this.iconCenterY, this.iconRadius + this.haloGap + this.haloThickness)
        }
        else {
            this.haloObject.clear()
        }
    }

    private drawOverlay = () => {
        if(this.hasTag(BoardTags.knockedOut)) {
            this.overlayObject.clear()
            this.drawKnockedOut(this.overlayObject)
            this.view.alpha = 0.3
        }
        else {
            this.overlayObject.clear()
            this.view.alpha = 1.0
        }
    }

    private buildLabel = () => {
        const label = new Text(this.getName(), labelStyle);
        label.anchor.set(0.5, 0.5)
        label.x = this.iconCenterX;
        label.y = this.haloSize + this.iconSize + this.standardTextSize;

        return label
    }

    private rebuildView = () => {
        this.iconSize = this.standardIconSize * this.pin.scale
        this.iconRadius = this.iconSize / 2
        this.iconCenterX = this.haloSize + (this.iconSize / 2)
        this.iconCenterY = this.haloSize + (this.iconSize / 2)

        const icon = new Container()
        icon.height = this.iconSize
        icon.width = this.iconSize

        const sprite = Sprite.from(buildMediaSourceWithDefault(this.media))
        sprite.anchor.set(0.5, 0.5)
        sprite.x = this.iconCenterX
        sprite.y = this.iconCenterY
        sprite.height = this.iconSize
        sprite.width = this.iconSize
        sprite.rotation = this.getRotation()

        const background = new Graphics()
            .beginFill(0xffffff)
            .drawCircle(this.iconCenterX, this.iconCenterY, this.iconRadius)
            .endFill();
        const mask = background.clone()

        icon.addChild(background)
        icon.addChild(sprite)
        icon.addChild(mask)
        icon.mask = mask

        this.borderObject = new Graphics()
        this.drawBorder()
        this.labelObject = this.buildLabel()
        this.haloObject = new Graphics()
        this.drawHalo()
        this.overlayObject = new Graphics()
        this.view.hitArea = this.buildHitArea()

        const container = this.view as Container
        container.removeChildren()
        container.pivot.set(this.iconCenterX, this.iconCenterY)
        container.height = this.iconSize + this.standardTextSize
        container.width = this.iconSize
        container.addChild(icon, this.borderObject, this.labelObject, this.haloObject, this.overlayObject)
        this.adjustForTags()
    }

    buildHitArea = (): IHitArea =>
        new Circle(this.iconCenterX, this.iconCenterY, this.iconRadius + this.haloGap + this.haloThickness)



}

const labelStyle = new TextStyle({
    fontFamily: "Comic Sans MS",
    fontSize: 22,
    fontWeight: '900',
    lineJoin: "round",
    padding: 1,
    stroke: "white",
    strokeThickness: 10,
    trim: true
});
