import {AppState} from "./state/AppState";
import {TypedMap} from "./domain/MapTypes.ds";
import {Dispatch} from "react";
import {
    getValuesFromObject,
    implementsChanges,
    implementsIdentity,
    setValuesInObject
} from "../common/function/MiscFunctions";
import {globalServices} from "./service/Services";
import updateStoredObjects from "../common/action/UpdateStoredObjects";
import {Changes, Identity} from "../repository/domain/ApiTypes";
import {IllegalArgumentException, SystemSetupException} from "../common/exception/Exceptions";

/**
 * Parent class for all Actions that do some effect and update the Redux state. This works with the useAction hook
 * and a customized reducer to ensure that StateAction implementations do not need to access any 3rd party libraries,
 * including Redux.
 *
 * To use:
 * - Extend this class
 * - Create a function as the entrypoint to this Action. In general, this will be the main logic of the Action
 *   and will generally contain any backend API calls or other calculations needed to setup the change to the State.
 * - Save any data that is needed for the reducer into public instance variables
 * - Any data in instance variables will be pulled from the Action object and passed along to the Redux queue
 * - The Action object will be reconstituted and the data from the Redux queue placed back into the Action
 * - The save() method will then be called to calculate the changed state
 *
 * In addition, this will do a minor dependency injection and inject services that the Action needs, as long as those
 * services are registered in the GlobalServices map. This last bit may be kinda unnecessary, but it seemed neat.
 */
export abstract class StateAction {
    public dispatch!: Dispatch<any>
    public state!: AppState

    /**
     * Subclasses must override to return a single hardcoded type string per class. This is used as the type of the
     * Redux reducer.
     */
    public abstract type(): string

    /**
     * Subclasses must override to update the given State in any way this Action needs. This method doesn't need
     * implementing if it is never called (no save to be done or the basic saves used). The returned value is set as
     * the new value of the state
     * NOTE: internally, the Immer pattern can be used if desired
     */
    public save = (state: AppState) : AppState => {
        throw new SystemSetupException(`A call is made to StateAction.save, but there is no overriding implementation - ${this.type()}`)
    }

    /**
     * Called by the useAction hook to prepare this object for the initial use
     */
    public queueSave = () => {
        const actionParameters: TypedMap<any> = getValuesFromObject(this, Object.keys(globalServices))
        actionParameters.type = this.type()
        this.dispatch!!(actionParameters)
    }

    /**
     * Call to queue up a simple save of Identites or Changes
     */
    public queueSimpleSave = (change: any) => {
        if(implementsChanges(change)) {
            this.dispatch(updateStoredObjects.buildFromChanges(change))
        }
        else if(change instanceof Array) {
            if(change.length > 0) {
                this.dispatch(updateStoredObjects.buildFromList(change))
            }
        }
        else if(implementsIdentity(change)) {
            this.dispatch(updateStoredObjects.buildFromOne(change))
        }
        else {
            throw new IllegalArgumentException(`Object queued for save as changes isn't the right type - ${this.type()}`)
        }
    }

    /**
     * Called by the reducer to reconstitute the Action object and call the updated State calculator
     */
    public reduceActionToState = (state: AppState, action: TypedMap<any>) : AppState => {
        setValuesInObject(this, action)
        return this.save(state)
    }
}
