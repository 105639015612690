/**
 * Trims the string to the given length, cutting off the extra characters on the right and adding an ellipsis
 * @param s the original string
 * @param len the maximum length of the returned string, including an ellipsis if needed
 */
export const trimStringWithEllipsis = (s: string, len: number) : string =>
    s.length + 3 > len ? `${s.substring(0, len - 3)}...` : s

/**
 * Returns true if the number is a field internal navigation key
 */
export const isFieldInternalNavigationKey = (keyCode: number) =>
     keyCode === 8 || keyCode === 46 || keyCode === 39 || keyCode === 37

/**
 * Returns true if the number is a field "finished editing" key
 */
export const isFieldSaveEditingKey = (keyCode: number) =>
    keyCode === 13 || keyCode === 9

/**
 * Returns true if the number is a field "finished editing" key
 */
export const isFieldCancelEditingKey = (keyCode: number) =>
    keyCode === 27

/**
 * Returns true if the number is a field "finished editing" key
 */
export const isFieldModifierKey = (keyCode: number) =>
    keyCode === 16 || keyCode === 17

/**
 * Returns true if the number is a field "plus" key
 */
export const isFieldPlusKey = (keyCode: number) =>
    keyCode === 187 || keyCode === 107

/**
 * Returns true if the number is a field "minus" key
 */
export const isFieldMinusKey = (keyCode: number) =>
    keyCode === 189 || keyCode === 109

/**
 * JSON Stringify's the given object
 */
export const js = (obj: any) =>
    JSON.stringify(obj)

export const isStringEmpty = (s: string | undefined | null) : boolean =>
    s === null || s === undefined || s.length == 0 || s.replaceAll("/\s/g", "").length === 0
