import {Container, DisplayObject} from "@pixi/display";
import {Point} from "@pixi/math";
import {ClipProxy, DataType, Media, NamedIdentity, Pin} from "../../../repository/domain/ApiTypes";
import {getPinName} from "../function/BoardFunctions";
import {PinView} from './PinView';
import {BoardTags, PinClickedHandler, PinPressedHandler, ViewBoardTags} from "../function/BoardTypes";
import {isClip} from "../../clip/ClipFunctions";
import {isPlayer} from "../../player/PlayerFunctions";
import {isMedia} from "../../../common/function/MediaFunctions";


export default abstract class AbstractPinView implements PinView {

    protected view: DisplayObject
    protected pin: Pin
    protected pinnedObject: NamedIdentity
    protected proxy: ClipProxy | undefined
    protected media: Media

    protected viewTags: string[] = []
    protected allowedTags: string[]

    protected handlePinClicked: PinClickedHandler | undefined
    protected handlePinPressed: PinPressedHandler | undefined

    private logStep = 0

    //TODO I think I can safely remove pinned object, text, media and replace with function calls
    protected constructor(view: Container, pin: Pin, pinnedObject: NamedIdentity, proxy: ClipProxy | undefined, media: Media, allowedTags: string[]) {
        this.view = view
        this.pin = pin
        this.pinnedObject = pinnedObject
        this.proxy = proxy
        this.media = media
        this.allowedTags = allowedTags
        this.adjustForTags()
    }

    public getId = () => {
        return this.pin.id
    }
    
    public getView = () : DisplayObject => {
        return this.view
    }

    public getPin = () : Pin => {
        return this.pin
    }

    public getPinnedObject = () : NamedIdentity => {
        if(isClip(this.pin.pinnedObject) && this.pin.clip) return this.pin.clip
        if(isPlayer(this.pin.pinnedObject) && this.pin.player) return this.pin.player
        if(isMedia(this.pin.pinnedObject) && this.pin.media) return this.pin.media
        return this.getPin().pinnedObject
    }

    public getProxy = () : ClipProxy | undefined => {
        return this.proxy
    }

    public getName = () : string => {
        return getPinName(this.pin)
    }

    public setAlias = (alias: string) => {
    }

    public getMedia = () : Media => {
        return this.media
    }

    public getScale = (): number => {
        return this.pin.scale
    }

    setScale(scale: number): void {
        this.pin.scale = scale
        this.view.scale.set(scale, scale)
    }

    public getTags = () : string[] => {
        return this.pin.tags
    }

    public getViewTags = () : string[] => {
        return this.viewTags
    }

    public addTag = (tag: string) : AbstractPinView => {
        if(this.allowedTags.includes(tag)) {
            if(ViewBoardTags.includes(tag as BoardTags)) {
                if(!this.viewTags.includes(tag)) {
                    this.viewTags.push(tag)
                }
            }
            else {
                if(!this.pin.tags.includes(tag)) {
                    this.pin.tags.push(tag)
                }
            }

            this.adjustForTags()
        }

        return this
    }

    public removeTag = (tag: string) : AbstractPinView => {
        const index = this.pin.tags.indexOf(tag)
        if(index > -1) {
            this.pin.tags.splice(index, 1)
        }

        const viewIndex = this.viewTags.indexOf(tag)
        if(viewIndex > -1) {
            this.viewTags.splice(viewIndex, 1)
        }

        this.adjustForTags()
        return this
    }

    public hasTag = (tag: string) : boolean =>
        (this.viewTags && this.viewTags.includes(tag)) || (this.pin.tags && this.pin.tags.includes(tag))

    public updatePin = (pin: Pin) : AbstractPinView => {
        this.pin = pin
        return this
    }

    public ownedByUser = (userId: string): boolean =>
        this.getPin().ownerId === userId

    public moveInWorld = (worldPoint: Point) => {
        this.pin.x = worldPoint.x
        this.pin.y = worldPoint.y
        this.view.position.x = worldPoint.x
        this.view.position.y = worldPoint.y
        // this.log(`Moved to point ${worldPoint}`)
    }

    public onPinClicked = (handler: PinClickedHandler) : AbstractPinView => {
        this.handlePinClicked = handler
        return this
    }
    
    protected adjustForTags = () => {
    }

    protected log = (s: string) => {
        console.log(`P-${this.logStep}: ${s}`)
        this.logStep++
    }
    
    public onPinPressed = (handler: PinPressedHandler) : AbstractPinView => {
        this.handlePinPressed = handler
        return this
    }

    protected getRotation = () : number =>
        this.getPin().angle * 0.0175

}
