import {buildIdentitiesChangedMessage, changesSyncLocationId} from '../function/DataSyncFunctions';
import {Reducer} from "../../repository/AppReducer";
import {Changes, Identity} from "../../repository/domain/ApiTypes";
import {AppState} from "../../repository/state/AppState";
import {UiState} from "../../repository/state/UiState";
import {updateStateWithChanges} from "../function/ActionFunctions";
import {SyncCommunicationChannel} from "../function/SyncCommunicationChannel";

interface ActionParameters {
    type: string,
    changes: Changes,
    uiChanges?: Partial<UiState>
}

/**
 * Updates the state with the given objects
 */
export class UpdateStoredObjects implements Reducer<ActionParameters> {
    public type = 'UpdateStoredObjects';

    public buildFromChanges = (changes: Changes, uiChanges?: Partial<UiState>) : ActionParameters => {
        SyncCommunicationChannel.shareLocalChange(changes)
        SyncCommunicationChannel.sendMessage(changesSyncLocationId, buildIdentitiesChangedMessage(changes))

        return ({
            type: this.type,
            changes,
            uiChanges
        })
    }

    public buildFromOne = (object: Identity, uiChanges?: Partial<UiState>) : ActionParameters =>
        this.buildFromChanges({others: [object]}, uiChanges)

    public buildFromList = (objects: Identity[], uiChanges?: Partial<UiState>) : ActionParameters =>
        this.buildFromChanges({others: objects} as Changes, uiChanges)

    public build = (object: Identity, uiChanges?: Partial<UiState>) : ActionParameters =>
        this.buildFromChanges({primary: object} as Changes, uiChanges);

    public reduce = (state: AppState, action: ActionParameters) : AppState => {
        return updateStateWithChanges(state, action.changes, action.uiChanges);
    };
}

const updateStoredObjects = new UpdateStoredObjects();
export default updateStoredObjects;