import { Identity } from "./ApiTypes";

export interface TypedMap<T> { [key: string]: T; }

/**
 * Creates a new map, copying the original and updating a member
 */
export const copyMap = <T>(original: TypedMap<T>) : TypedMap<T> => {
    const updated : TypedMap<T> = {};
    Object.keys(original).forEach(id => updated[id] = original[id]);

    return updated;
}

/**
 * Creates a new map, copying the original and updating a member
 */
export const copyTypedMapWithUpdate = <T>(original: TypedMap<T>, updatedId: string, updatedValue: T) : TypedMap<T> => {
    const updated : TypedMap<T> = {};
    Object.keys(original).forEach(id => updated[id] = original[id]);
    updated[updatedId] = updatedValue;

    return updated;
};

export const unpackToMap = <T extends Identity>(objects: T[]): TypedMap<T> => {
    const targetMap: TypedMap<T> = {};

    objects.forEach(b => {
        targetMap[b.id] = b;
    });

    return targetMap
};

export const unpackAnyToMap = <T extends any>(objects: T[], idFunc: (obj: any) => string): TypedMap<T> => {
    const targetMap: TypedMap<T> = {};

    objects.forEach(b => {
        targetMap[idFunc(b)] = b;
    });

    return targetMap
};

/**
 * Returns the values of the map or null if the map is null
 */
export const mapValues = <T extends any>(map: TypedMap<T> | undefined): T[] => {
    if(map) {
        return Object.values(map)
    }
    else {
        return []
    }
}

export const mapLength = (map: TypedMap<any>) =>
    Object.values(map).length

