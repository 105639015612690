import BaseRepository from "../BaseRepository";
import {DataType, Identity, Media, PartialMedia} from "../domain/ApiTypes";

/**
 * A service to provide data services for media items
 */
export class MediaDataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository(DataType.media);
    }
    
    /**
     * Finds media with he given ID
     */
    public find = (id: string) : Promise<Media> =>
        this.repository.findSingleItem(id);
 
    /**
     * Finds media matching the name of the media
     */
    public findAll = (adventureId: string) : Promise<Media[]> =>
        this.repository.findAllInCollection(adventureId);

    /**
     * Creates the given media as part of this adventure
     * @param mediaInfo the information about the media (kind, key, etc)
     */
    public createMedia = (owner: Identity, mediaInfo: PartialMedia) : Promise<Media> => {
        return this.repository.createInCollectionWithDetails(owner, mediaInfo)
    };

}

export const mediaDataService = new MediaDataService()