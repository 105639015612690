import {Identity} from "../../../repository/domain/ApiTypes";
import {StateAction} from "../../../repository/StateAction";
import {getBoardOwningDataService} from "../function/BoardFunctions";

/**
 * Creates the board for the given clip
 */
export class CreateBoardForOwner extends StateAction {
    type = () => "CreateBoardForOwner"

    public create = (owner: Identity) => {
        // dispatch(startStoreOperation());   // TODO put this back in

        getBoardOwningDataService(owner.type).createBoard(owner.id)
            .then(this.queueSimpleSave)
    }
}


