import {playerDataService} from '../../repository/service/PlayerDataService';
import {sceneDataService} from '../../repository/service/SceneDataService';
import {stageDataService} from '../../repository/service/StageDataService';
import {sessionDataService} from '../../repository/service/SessionDataService';
import {informationDataService} from '../../repository/service/InformationDataService';
import {mediaDataService} from '../../repository/service/MediaDataService';
import {journalEntryDataService} from '../../repository/service/JournalEntryDataService';
import {boardDataService} from '../../repository/service/BoardDataService';
import {characterDataService, locationDataService, plotDataService} from '../../repository/service/ClipDataService';
import {adventureDataService} from '../../repository/service/AdventureDataService';
import {Changes, SyncConnectionConfig as ClientCommConfig, DataType, Identity} from './../../repository/domain/ApiTypes';
import {AppState} from "../../repository/state/AppState";
import {DataNotLoadedException} from '../exception/Exceptions';
import {journalDataService} from '../../repository/service/JournalDataService';
import {pinDataService} from '../../repository/service/PinDataService';
import {clipProxyDataService} from '../../repository/service/ClipProxyDataService';
import {SyncEvent} from "./SyncCommunicationChannel";

/**
 * Returns the configuration for the connection to the commmuncations around sharing updates
 */
 export const getCommChannelConfig = (state: AppState) : ClientCommConfig => {
    if(state.ui.commChannelConfig) return state.ui.commChannelConfig;
    throw new DataNotLoadedException()
};

export const changesSyncLocationId = "changes"
export const changesSyncEventType = "changes"

export interface IdentitiesChangedEvent extends SyncEvent {
    changes: Changes
}

export const refetchIdentity = (identity: Identity) : Promise<Identity> | null => {
    switch(identity.type) {
        case DataType.adventure: return adventureDataService.findOne(identity.id)
        case DataType.game: return adventureDataService.findOne(identity.id)
        case DataType.character: return characterDataService.findOne(identity.id)
        case DataType.location: return locationDataService.findOne(identity.id)
        case DataType.plot: return plotDataService.findOne(identity.id)
        case DataType.entry: return journalEntryDataService.findOne(identity.id)
        case DataType.media: return mediaDataService.find(identity.id)
        case DataType.information: return informationDataService.findOne(identity.id)
        case DataType.journal: return journalDataService.find(identity.id)
        case DataType.session: return sessionDataService.find(identity.id)
        case DataType.board: return boardDataService.find(identity.id)
        case DataType.pin: return pinDataService.find(identity.id)
        case DataType.clipProxy: return clipProxyDataService.findOne(identity.id)
        case DataType.stage: return stageDataService.fetch(identity.id)
        case DataType.scene: return sceneDataService.find(identity.id)
        case DataType.player: return playerDataService.findOne(identity.id)

        default: return null
    }
    // TODO do we need these?: actor, user
}

export const simplifyIdentity = (identity: Identity) : Identity => {
    return ({
        type: identity.type,
        id: identity.id,
        lastUpdatedAt: identity.lastUpdatedAt,
    })
}

export const simplifyChanges = (changes: Changes) : Changes => {
    const allUpdates = (changes.primary ? [changes.primary] : []).concat(changes.others ? changes.others : [])
    const allDeletions = changes.deletions ? changes.deletions : []

    const simplifiedChanges : Changes = {
        primary: undefined,
        others: allUpdates.map(u => simplifyIdentity(u)),
        deletions: allDeletions.map(u => simplifyIdentity(u))
    }

    return simplifiedChanges
}

export const buildIdentitiesChangedMessage = (changes: Changes) : IdentitiesChangedEvent => {
    return ({
        eventType: changesSyncEventType,
        changes: simplifyChanges(changes),
    })
}