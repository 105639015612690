import {Dispatch} from "react";
import {AppState} from "../../../repository/state/AppState";
import {updateStateWithObjects} from "../../../common/function/ActionFunctions";
import {Identity} from "../../../repository/domain/ApiTypes";
import {Reducer} from "../../../repository/AppReducer";
import {viewDataService} from "../../../repository/service/ViewDataService";

interface ActionParameters {
    type: string,
    items: Identity[],
}

/**
 * Loads a Journal Session into memory
 */
export class LoadSession implements Reducer<ActionParameters> {
    public type = 'LoadSession';


    public load = (sessionId: string) => (dispatch: Dispatch<any>, getState: () => AppState) => {
        // dispatch(startStoreOperation());   // TODO put this back in

       viewDataService.fetchDataForViewSession(getState().game!!.id, sessionId)
           .then(sessionAndEntries =>
                dispatch({
                    type: this.type,
                    items: sessionAndEntries,
                }))
    }

    public reduce = (state: AppState, action: ActionParameters) : AppState => {
        const newState: AppState = {
            ...state,
            sessions: {},
            entries: {},
        }

        return updateStateWithObjects(newState, action.items)
    }

}

const loadSession = new LoadSession();
export default loadSession;