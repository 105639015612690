import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";
import {
    Clip,
    InformationReference,
    Information,
    InformationPurpose,
    PartialInformationReference
} from "../../repository/domain/ApiTypes";
import { TypedMap } from "../../repository/domain/MapTypes.ds";
import { DataNotLoadedException } from "../../common/exception/Exceptions";
import { AppState } from "../../repository/state/AppState";
import { getUiState } from "../../repository/UiFunctions";

/**
 * Returns the map of all information or throws an error if it isn't initialized
 */
export const getAllInformationMap = (state: AppState): TypedMap<Information> => {
    if (state.information) return state.information;
    throw new DataNotLoadedException()
};

export const getInformation = createCachedSelector(
    getAllInformationMap,
    (state: AppState, infoId: string) => infoId,
    (allInformation, informationId) => allInformation[informationId],
)(
    (_, informationId) => informationId
);

/**
 * Returns the ID currently selected information
 */
export const getSelectedInformationId = createSelector(
    [getUiState],
    (uiState) => uiState.editingInformationId !== null ? uiState.editingInformationId : null
);

/**
 * Returns the value of the currently selected information
 */
export const getSelectedInformationOrNull = createSelector(
    [getAllInformationMap, getSelectedInformationId],
    (informationMap, getSelectedInformationId) => getSelectedInformationId !== null ? informationMap[getSelectedInformationId] : null
);

export const getInformationReference = (clip: Clip, informationId: string): InformationReference | undefined =>
    clip.information.find(i => i.id === informationId);

export const purposesContains = (purposes: InformationPurpose[], purpose: InformationPurpose): boolean =>
    purposes.find(p => p === purpose) !== undefined;

export const doesInformationReferenceHavePurpose = (informationReference: InformationReference | undefined, purpose: InformationPurpose): boolean =>
    informationReference !== undefined && purposesContains(informationReference.purposes, purpose);

export const isInformationKnown = (clip: Clip, informationId: string): boolean =>
    doesInformationReferenceHavePurpose(getInformationReference(clip, informationId), "known" as InformationPurpose);

export const isInformationAbout = (clip: Clip, informationId: string): boolean =>
    doesInformationReferenceHavePurpose(getInformationReference(clip, informationId), "about" as InformationPurpose);

export const isInformationDiscovery = (clip: Clip, informationId: string): boolean =>
    doesInformationReferenceHavePurpose(getInformationReference(clip, informationId), "discovery" as InformationPurpose);

export const addPurpose = (purposes: InformationPurpose[], purpose: InformationPurpose) =>
    purposesContains(purposes, purpose) ? purposes : purposes.concat(purpose);

export const removePurpose = (purposes: InformationPurpose[], purpose: InformationPurpose) =>
    purposes.filter(p => p !== purpose);

export const calcPartialInformationSettingPurpose = (reference: InformationReference, purpose: InformationPurpose, isActive: boolean): PartialInformationReference => ({
    description: reference.description,
    order: reference.order,
    purposes: isActive ? addPurpose(reference.purposes, purpose) : removePurpose(reference.purposes, purpose)
});


/**
 * Searches the given information map for any media whose name matches the given text. Currently, this is very simple.
 */
export const searchInformation = (allInformation: TypedMap<Information> | undefined, searchText: string) : Information[] => {
    if(!allInformation) return []
    const regExp = new RegExp(searchText, "i");
    return Object.values(allInformation).filter(i => (i.name && i.name.match(regExp)) || (i.details && i.details.match(regExp)))
}
