import {
    Adventure,
    Board,
    Clip,
    Game,
    Information,
    Journal,
    JournalEntry,
    Media, Pin,
    Player,
    Scene,
    Session,
    Stage, Tracker,
    User
} from "../domain/ApiTypes";
import {TypedMap} from "../domain/MapTypes.ds";
import {LobbyState} from "./LobbyState";
import {initialUiState, UiState} from "./UiState";

/**
 * The Redux state of the application
 */
export interface AppState {

    /** The currently loaded Adventure */
    adventure: Adventure | null;

    /** The currently loaded Game */
    game: Game | null;   // Some better way to do this? Maybe make game a sub state and put stage, journal, etc there

    /** The currently loaded Player */
    player: Player | null,

    /** The players in the current game */
    participantPlayers: TypedMap<Player>

    /** The users that own the players in the current game */
    participantUsers: TypedMap<User>

    /** The stage of the current game */
    stage: Stage | null;

    /** The Journal associated with the currently loaded Game */
    journal: Journal | null;

    /** The boards for the current adventure */
    boards: TypedMap<Board>,

    /** The pins on the current board */
    pins: TypedMap<Pin>,

    /** All the sessions, mapped by ID */
    sessions: TypedMap<Session>;

    /** All the Entries in all the Sessions in the Journal, mapped by ID */
    entries: TypedMap<JournalEntry>;

    /** All the CLIPs in the given adventure, mapped by ID */
    clips: TypedMap<Clip>;

    /** All information objects, mapped by ID */
    information: TypedMap<Information>;

    /** All media objects, mapped by ID */
    media: TypedMap<Media>,

    /** All scene objects, mapped by ID */
    scenes: TypedMap<Scene>,

    /** All tracker objects, mapped by ID */
    trackers: TypedMap<Tracker>,

    /** The current state of the UI */
    ui: UiState,

    /** The currently logged in user and their permissions */
    user: User | null,

    // Lobby objects

    /** No longer used; remove - or go back to using it */
    /** What really needs to happen here is that the Lobby needs to be a different app */
    lobby: LobbyState | null,

}

export const initialState: AppState = {
    adventure: null,
    game: null,
    player: null,
    participantPlayers: {},
    participantUsers: {},
    stage: null,
    journal: null,
    boards: {},
    pins: {},
    sessions: {},
    entries: {},
    clips: {},
    information: {},
    media: {},
    scenes: {},
    trackers: {},
    user: null,
    ui: initialUiState,
    lobby: null,
};

export const clearStateForLoading = (state: AppState) => (
    {
        ...initialState,
        user: state.user,
        ui: {...initialUiState,
            commChannelConfig: state.ui.commChannelConfig
        }
    }
)

/**
 * Clears the state of everything specific to the current view, but leaves everything needed by the game alone.
 * This is used to reset the player/stage/journal, but leave the game data alone
 */
export const clearStateDownToGame = (state: AppState) => (
    {
        ...initialState,
        adventure: state.adventure,
        game: state.game,
        participantPlayers: state.participantPlayers,
        participantUsers: state.participantUsers,
        media: state.media, // TODO this isn't right, we should be clearing all media by the game's and the particpants', but until we maintain media by owner, its what we have
        user: state.user,
        ui: {...initialUiState,
            mode: state.ui.mode,
            commChannelConfig: state.ui.commChannelConfig
        }
    }
)


