
export enum DataType {
    unspecified = "unspecified",
    adventure = "adventure",
    bookmark = "bookmark",
    game = "game",
    clip = "clip",
    character = "character",
    location = "location",
    item = "item",
    plot = "plot",
    entry = "entry",
    discovery = "discovery",
    learning = "learning",
    writing = "writing",
    media = "media",
    information = "information",
    journal = "journal",
    session = "session",
    board = "board",
    pin = "pin",
    clipProxy = "clipProxy",
    stage = "stage",
    scene = "scene",
    actor = "actor",
    player = "player",
    tracker = "tracker",
    user = "user"
}

export interface Identity {
    id: string;
    type: DataType;
    lastUpdatedAt: number 
}

export interface NamedIdentity extends Identity {
    name: string
}


export interface Reference {
    id: string;
    type: DataType;
}

export enum MediaPurpose {
    icon = "icon",
    background = "background"
}

export interface MediaReference extends Reference {
    description: string | null;
    name: string | null;
    order: number | null;
    purposes: MediaPurpose[];
}

export interface HasMedia extends NamedIdentity {
    media: MediaReference[]
}


export interface HasInformation extends NamedIdentity {
    information: InformationReference[]
}

export interface HasTrackers extends NamedIdentity {
    trackers: Tracker[];
}

export interface Collection extends NamedIdentity, HasMedia {
    description: string;
    details: string;
}

export interface Adventure extends Collection {
    overviewBoard: BoardReference
    bookmarks: Identity[]
    builtInPlayers: Player[]
    games: Game[]
}

export interface Game extends Collection {
    adventure: AdventureReference;
    journal: JournalReference;
    players: Player[];
    stage: StageReference;
    currentScene: Scene;
    bookmarks: Identity[];
}

export interface NewGame {
    adventureId: string;
}

export interface BoardReference extends Identity {
}

export interface AdventureReference extends Reference {
    name: string;
}

export interface Changes {
    deletions?: Identity[];
    others?: Identity[];
    primary?: Identity;
}

export type InformationPurpose = "known" | "about" | "discovery"; // TODO change to enum


export interface ClipReference extends Reference {
    condition: string | null;
    name: string;
    order: number;
}

export interface Clip extends NamedIdentity, HasInformation, HasTrackers, HasMedia {
    descriptiveName: string | null
    description: string | null
    details: string | null
    information: InformationReference[]
    board: BoardReference | null;
    parts: ClipReference[]
    trackers: Tracker[]
}

export interface ClipProxy extends Identity, HasTrackers {
    clip: Clip;
    trackers: Tracker[]
}

export interface Character extends Clip {
}

export interface CombinedDate {
    game: string | null;
    real: string;
    realFriendly: string;
}

export interface JournalEntry extends Identity {
    date: string;
    from: ClipReference | null;
    information: InformationReference | null;
    discovery: ClipReference | null;
    scene: Reference | null;
    board: Reference | null
    details: string | null;
    author: Reference | null;
}

export interface JournalReference extends Reference {
}

export interface PlayerCharacterReference extends Reference {
    name: string;
}

export interface StageReference extends Reference {
}

export interface Information extends HasMedia {
    details: string | null;
    name: string;
    board: BoardReference | null;
}

export interface InformationReference extends Reference {
    order: number
    purposes: InformationPurpose[]
    description: string
}

// TODO remove these extra reference classes, now that they are gone from the BE
export interface SessionReference extends Reference {
}

export interface Journal extends Identity {
    currentSessionId?: string
    sessions: SessionReference[]
    discoveryBoard: BoardReference
}

export interface JournalEntryReference extends Reference {
}

export interface Location extends Clip {
}

export interface PinReference extends Identity {
}

export interface Board extends Identity {
    pins: Pin[]
    veils: RegularPolygonGraphic[]
    areas: PolygonGraphic[]
    lockBackground: boolean
    showGrid: boolean
    showVeils: boolean
    hideBackground: boolean
}

export enum MediaKeyType {
    internal = "internal",
    url = "url",
    cloudinary = "cloudinary"
}

export enum MediaKind {
    image = "image",
    video = "video",
    audio = "audio"
}

export interface Media extends NamedIdentity {
    description: string | null;
    key: string;
    keyType: MediaKeyType;
    kind: MediaKind;
    height: number,
    width: number
}

export interface PartialAdventure {
    details: string | null;
    name: string | null;
}

export interface PartialAdventureMember {
    name: string | null;
}

export interface PartialClip extends PartialAdventureMember {
    type: DataType | null
    descriptiveName: string | null
    description: string | null
    details: string | null
}

export interface NewClip extends PartialClip {
    type: DataType
}

export interface PartialGame {
    adventureId: string | null;
    name: string | null;
    details: string | null;
}

export interface PartialInformationReference {
    description: string | null;
    order: number | null;
    purposes: InformationPurpose[] | null;
}

export interface PartialBoard {
    backgroundScale?: number
    lockBackground?: boolean
}

export interface PartialMedia {
    description: string | null;
    height: number | null;
    key: string | null;
    keyType: MediaKeyType | null;
    kind: MediaKind | null;
    name: string | null;
    width: number | null;
}

export interface PartialMediaReference {
    description: string | null;
    order: number | null;
    purposes: MediaPurpose[] | null;
}

export interface PartialPin {
    pinnedObjectId?: string
    x?: number
    y?: number
    alias?: string
    pinType?: PinType
    scale?: number
    tags?: string[]
    playerId?: string
}

export interface PartialPlayer {
    description: string | null;
    name: string | null;
    details: string | null;
}

export interface PartialJournalEntry {
    details: string | null;
}

export enum PinType {
    actor = "actor",
    scenery = "scenery",
    background = "background"
}

export interface Pin extends Identity {
    pinnedObject: NamedIdentity
    proxy: ClipProxy | undefined
    clip: Clip | undefined
    player: Player | undefined
    media: Media | undefined
    isDiscovered: Boolean
    x: number
    y: number
    z: number
    alias: string
    pinType: PinType
    scale: number
    angle: number
    tags: string[]
    ownerId: string
    lastModifiedAt: number
}

export interface Player extends NamedIdentity, HasTrackers {
    description: string;
    details: string;
    media: MediaReference[]
    overviewBoard: BoardReference
    currentCharacter: Identity
    owner: Reference
    trackers: Tracker[]
}

export interface Plot extends Clip {
}

export interface Session extends Identity {
    name: string;
    endDate: string | undefined;
    entries: JournalEntryReference[];
    startDate: string;
}


export interface Stage extends Identity {
    identities: Identity[]
}

export interface Scene extends Identity {
    board: Board,
    startingNotes: string,
    endingNotes: string,
    playerNotes: string,
    turnNumber: number,
    actors: Reference[]
}

export enum TrackerType {
    counter = "counter",
    checkbox = "checkbox",
}

export enum TrackerViewStyle {
    checkbox = "checkbox",
    number = "number",
}

export interface Tracker extends Identity {
    name: string,
    trackerType: TrackerType,
    categories: string[],
    viewStyle: TrackerViewStyle,

    defaultValue: number,
    currentValue: number,
    minValue: number,
    maxValue: number,
}

export interface User extends Identity {
    accountName: string;
    name: string;
    media: MediaReference[];
}

export interface UserWithPlayers extends Identity {
    accountName: string | null;
    name: string;
    players: Player[];
}

export interface SyncConnectionConfig {
    url: string
    clientId: string
}


export enum ShapeType {
    polygon = "polygon",
    regularPolygon = "regularPolygon",
}

export interface Point {
    x: number,
    y: number
}

export interface Graphic {
    localId: string,
    shape: ShapeType,
}

export interface RegularPolygonGraphic extends Graphic, Point {
    size: number,
    sideCount: number,
}

export interface PolygonGraphic extends Graphic {
    points: Point[],
}

/**
 * Holds the data for an information object that is newly learned
 */
export interface NewLearning {
    informationId: String,
    fromId?: String,
}