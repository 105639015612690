import BaseRepository from "../BaseRepository";
import {Identity} from "../domain/ApiTypes";

/**
 * A service to provide data services for retrieving the data necessary for rendering views
 */
export class ViewDataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository("view");
    }

    /**
     * Finds the data to start a Narrator running a Game
     */
    public fetchDataForRunGame = (gameId: string) : Promise<Identity[]>=> {
        return this.repository.findNamedObjectsWithParameters("run/game", {game: gameId})
    }


    /**
     * Finds the data to start a Player playing a Game
     */
    public fetchDataForPlayGame = (gameId: string, playerId: string) : Promise<Identity[]>=> {
        return this.repository.findNamedObjectsWithParameters("play/single", {game: gameId, player: playerId})
    }

    /**
     * Finds the data to view a session in the journal
     */
    public fetchDataForViewSession = (gameId: string, sessionId: string) : Promise<Identity[]>=> {
        return this.repository.findNamedObjectsWithParameters("play/session", {game: gameId, session: sessionId})
    }

    /**
     * Finds the data to start a Player playing a Game
     */
    public fetchDataForPlayGameShared = (gameId: string) : Promise<Identity[]>=> {
        return this.repository.findNamedObjectsWithParameters("play/shared", {game: gameId})
    }

    /**
     * Finds the data for a Player playing a Game
     */
    public loadPlayerInSharedGame = (gameId: string, playerId: string) : Promise<Identity[]>=> {
        return this.repository.findNamedObjectsWithParameters("play/player", {game: gameId, player: playerId})
    }


}

export const viewDataService = new ViewDataService()