import BaseRepository from "../BaseRepository";
import {DataType} from "../domain/ApiTypes";

/**
 * A service to provide data services for creating Boards on objects that own them
 */
export class OwningBoardDataService {
    private readonly repository: BaseRepository;

    constructor(dataType: DataType) {
        this.repository = new BaseRepository(dataType);
    }

    /**
     * Creates a board for the given item
     * @param clipId the ID of item that is to be added to
     * @returns a promise with the updated item
     */
    public createBoard(clipId: string) {
        return this.repository.addNewSubObject(clipId, DataType.board)
    }


}

export const characterBoardDataService = new OwningBoardDataService(DataType.character)
export const locationBoardDataService = new OwningBoardDataService(DataType.location)
export const itemBoardDataService = new OwningBoardDataService(DataType.item)
export const plotBoardDataService = new OwningBoardDataService(DataType.plot)
export const informationBoardDataService = new OwningBoardDataService(DataType.information)
