import { DataNotLoadedException } from "../common/exception/Exceptions";
import { AppState } from "./state/AppState";
import { UiState } from "./state/UiState";

export const getUiState = (state: AppState) : UiState => {
    if(state.ui) {
        return state.ui;
    }
    throw new DataNotLoadedException()
};
