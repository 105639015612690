import BaseRepository from "../BaseRepository";
import {Changes, DataType, Identity, Tracker, TrackerType} from "../domain/ApiTypes";
import {DataService} from "./DataService";
import {newApiObject} from "../../common/function/MiscFunctions";

/**
 * A service to provide data services for retrieving, updating, deleting trackers
 */
export class TrackerDataService implements DataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository(DataType.tracker);
    }

    /**
     * Finds a single tracker, by ID
     */
    public findOne = (trackerId: string) => {
        return this.repository.findSingleItem(trackerId)
    }

    /**
     * Adds a new unnamed tracker referring to the given adventure
     * @returns a promise returning the updated item
     */
    public create = (trackerType: TrackerType, owner?: Identity) => {
        return this.repository.createWithDetails(newApiObject({trackerType} as Partial<Tracker>, owner))
    }

    /**
     * Saves the given updates to the tracker with the given ID
     */
    public updateItemFields = (trackerId: string, updated: Partial<Tracker>): Promise<Changes> => {
        return this.repository.updateItem(trackerId, updated) as Promise<Changes>
    }

    /**
     * Completely deletes a tracker
     * @param id the ID to be removed
     */
    public delete = (id: string) => {
        return this.repository.deleteItem(id)
    }

}

export const trackerDataService = new TrackerDataService()