import {Dispatch} from "react";
import {Adventure, Game, HasInformation, HasMedia, Media, Player} from "../../repository/domain/ApiTypes";
import {TypedMap, unpackToMap} from '../../repository/domain/MapTypes.ds';
import {adventureDataService} from '../../repository/service/AdventureDataService';
import {gameDataService} from '../../repository/service/GameDataService';
import {playerDataService} from '../../repository/service/PlayerDataService';
import {AppState, initialState} from '../../repository/state/AppState';
import {AppMode} from "../../repository/state/UiState";
import {Reducer} from '../../repository/AppReducer';
import {mediaDataService} from '../../repository/service/MediaDataService';
import {userDataService} from "../../repository/service/UserDataService";

interface ActionParameters {
    type: string,
    adventuresImWriting: TypedMap<Adventure>,
    gamesImRunning: TypedMap<Game>,
    playersImPlaying: TypedMap<Player>,
    media: Media[]
}

/**
 * Loads the data for the lobby
 */
export class LoadLobbyAction implements Reducer<ActionParameters> {
    type: string = "LoadLobbyAction";

    public build = () => (dispatch: Dispatch<any>, getState: () => AppState) => {
        // dispatch(startStoreOperation());   // TODO put this back in

        Promise.all([
            adventureDataService.findAll(),
            gameDataService.findAll(),
            playerDataService.findAll(),
            userDataService.findCurrentUser(),
        ]).then(([adventuresImWriting, gamesImRunning, playersImPlaying, currentUser]) => {
            const mediaIds = this.gatherMediaIds([adventuresImWriting, gamesImRunning, playersImPlaying, currentUser])
            const mediaPromises = mediaIds.map(mId => mediaDataService.find(mId))
            Promise.all(mediaPromises)
                .then(media => dispatch({
                    type: this.type,
                    adventuresImWriting,
                    gamesImRunning,
                    playersImPlaying,
                    media,
                }))
        })
    }

    public reduce = (state: AppState, action: ActionParameters): AppState => {

        // console.log("Reducing clear selected information");
        return ({
            ...initialState,
            user: state.user,
            media: unpackToMap(action.media),
            ui: {
                ...state.ui,
                mode: AppMode.LOBBY,
            },
            lobby: {
                adventuresImWriting: action.adventuresImWriting,
                gamesImRunning: action.gamesImRunning,
                playersImPlaying: action.playersImPlaying,
            }
        });
    }

    private gatherMediaIds = (owners: HasMedia[][]): string[] => owners.flatMap(o => o).flatMap(o => o.media ? o.media : []).map(m => m.id)

}

const loadLobbyAction = new LoadLobbyAction();
export default loadLobbyAction;