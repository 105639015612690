import {Dispatch} from 'react';
import {Reducer} from "../../repository/AppReducer";
import {AppState} from "../../repository/state/AppState";
import {initialState} from './../../repository/state/AppState';
import {SyncCommunicationChannel} from "../function/SyncCommunicationChannel";
import {AppMode, initialUiState} from "../../repository/state/UiState";
import {Media} from "../../repository/domain/ApiTypes";
import {unpackToMap} from "../../repository/domain/MapTypes.ds";

interface ActionParameters {
    type: string,
    userMedia: Media[]
}

/**
 * Logs the user out and sends them back to the login screen
 */
export class PrepareForLobby implements Reducer<ActionParameters> {
    public type = 'PrepareForLobby'

    public build = () => (dispatch: Dispatch<any>, getState: () => AppState) => {
        SyncCommunicationChannel.destroy()
        dispatch({
            type: this.type,
            userMedia: getState().user?.media.map(mr => getState().media[mr.id])
        })
    }

    public reduce = (state: AppState, action: ActionParameters): AppState => {
        return {...initialState,
            user: state.user,
            media: unpackToMap(action.userMedia),
            ui: {...initialUiState,
                commChannelConfig: state.ui.commChannelConfig,
                mode: AppMode.LOBBY,
            }
        }
    }
}

const prepareForLobby = new PrepareForLobby();
export default prepareForLobby;