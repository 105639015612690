import * as React from "react";
import {Point} from "../../repository/domain/ApiTypes";

export interface CenteredGraphics {
    x: number,
    y: number,
    x2: number,
    y2: number,
    width: number,
    height: number,
    radius: number,
}

export const centerInBounds = (width: number, height: number) : CenteredGraphics => ({
    x: -(width / 2),
    y: -(height / 2),
    x2: width / 2,
    y2: width / 2,
    width: width,
    height: height,
    radius: Math.min(width, height) / 2
})

export const centerInBoundsWithBorder = (width: number, height: number, borderSize: number) : CenteredGraphics => ({
    x: -(width / 2) + borderSize,
    y: -(height / 2) + borderSize,
    x2: width / 2 - borderSize,
    y2: height / 2 - borderSize,
    width: width - borderSize,
    height: height - borderSize,
    radius: Math.min(width, height) / 2 - borderSize
})


export const centerInBoundsAtPoint = (x: number, y: number, width: number, height: number) : CenteredGraphics => ({
    x: x - (width / 2),
    y: y - (height / 2),
    x2: x + (width / 2),
    y2: y + (height / 2),
    width: width,
    height: height,
    radius: Math.min(width, height) / 2

})

export const boundingBoxString = (center: CenteredGraphics) => `${center.x} ${center.y} ${center.width} ${center.height}`

export const standardWidth = 100
export const standardHeight = 100
export const standardRadius = 50

export const labelY = (scale: number) => (standardRadius + 16) * scale
export const labelFontSize = () => "18px"

export const standardCenteredGraphics : CenteredGraphics = centerInBounds(standardWidth, standardHeight)

export const drawingDefinitions = () => {
    const c = standardCenteredGraphics
    const r = rhombusPoints(c)

    return [
    <clipPath id="circleClip" key="circleClipPath">
        <circle cx="0" cy="0" r="50" />
    </clipPath>,
    <clipPath id="rhombusClip" key="rhombusClipPath">
        <polygon points={`${r[0].x},${r[0].y} ${r[1].x},${r[1].y} ${r[2].x},${r[2].y} ${r[3].x},${r[3].y}`}/>
    </clipPath>,
    ]
}


//
// Circle shapes, for Characters
//
export const circleBackground = (coord: CenteredGraphics) => (
    <circle cx={0} cy={0} r={coord.width / 2} style={{fill: "white"}}/>
)
export const circleBorder = (coord: CenteredGraphics) => {
    return <circle cx={0} cy={0} r={coord.width / 2} fill="none" stroke="black"  strokeWidth="1mm"/>
}
export const circleClipPath = (coord: CenteredGraphics) => (
    "url(#circleClip)"
)

//
// Rhombus, used for location
//
export const rhombusPoints = (coord: CenteredGraphics) : Point[] => {
    const xIndent = coord.width * 0.10
    return [
        {x: coord.x + xIndent, y: coord.y},
        {x: coord.x2, y: coord.y},
        {x: coord.x2 - xIndent, y: coord.y2},
        {x: coord.x, y: coord.y2}
    ]
}
export const rhombusBackground = (coord: CenteredGraphics) => {
    const p = rhombusPoints(coord)
    return <polygon points={`${p[0].x},${p[0].y} ${p[1].x},${p[1].y} ${p[2].x},${p[2].y} ${p[3].x},${p[3].y}`} style={{fill: "white"}}/>
}
export const rhombusBorder = (coord: CenteredGraphics) => {
    const p = rhombusPoints(coord)
    return <polygon className="node" points={`${p[0].x},${p[0].y} ${p[1].x},${p[1].y} ${p[2].x},${p[2].y} ${p[3].x},${p[3].y}`} strokeWidth={3}/>
}

export const rhombusClipPath = () => {
    return "url(#rhombusClip)"
}

//
// Rectangle shapes, for Media or background
//
export const rectangleBackground = (coord: CenteredGraphics) => (
    <rect x={coord.x} y={coord.y} height={coord.width} width={coord.width} style={{fill: "white"}}/>
)
export const rectangleBorder = (coord: CenteredGraphics) => {
    return <rect className="node" x={coord.x} y={coord.y} width={coord.width} height={coord.height} strokeWidth="1mm"/>
}
export const rectangleClipPath = () =>
    "polygon(0% 0, 100% 0%, 100% 100%, 0% 100%)"

