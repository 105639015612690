
export default class BaseApi {
    private jsonHeaders = {
        "Content-Type": "application/json"
    };

    /**
     * POSTs a body to a URL
     * @returns a promise with the changed state
     */
    public post = (url: string, body: any) : Promise<any> => {
        return fetch(url, {
            method: "POST",
            headers: this.jsonHeaders,
            body: JSON.stringify(body)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed remote call');
                }
            })
            .catch(err => Promise.reject(err))
    };

}
