import { p0 } from './../function/PixiDrawing';
import { Container } from "@pixi/display";
import { Point } from "@pixi/math";
import { Sprite } from "@pixi/sprite";
import { buildMediaSourceWithDefault } from "../../../common/function/MediaFunctions";
import { Media, Pin } from "../../../repository/domain/ApiTypes";
import { drawCrosshairs } from "../function/PixiDrawing";
import AbstractPinView from "./AbstractPinView";


export default class BackgroundPinView extends AbstractPinView {

    constructor(pin: Pin, media: Media) {
        super(new Container(), pin, media, undefined, media, [])

        const container = this.view as Container
        const sprite = Sprite.from(buildMediaSourceWithDefault(media))
        // sprite.pivot.set(media.width / 2, media.height / 2)
        // sprite.x = 0
        // sprite.y = 0
        container.addChild(sprite)
        //container.addChild(drawCrosshairs(p0, 0x0314FC, 5))

        container.width = media.width
        container.height = media.height
        container.pivot.set(media.width / 2, media.height / 2)
        container.x = 0
        container.y = 0
        container.scale.x = pin.scale
        container.scale.y = pin.scale

    }

}
