import {journalDataService} from "../../../repository/service/JournalDataService";
import {getJournalId} from "../../journal/JournalFunctions";
import {StateAction} from "../../../repository/StateAction";


/**
 * Marks the Clip with the given ID as discovered or not discovered
 */
export class MarkClipDiscovery extends StateAction {
    public type = () => 'MarkClipDiscoveryAction'

    public flipDiscovery = (clipId: string, discovered: boolean) => {
        // dispatch(startStoreOperation());   // TODO put this back in

        if(discovered) {
            journalDataService.markAsDiscovered(getJournalId(this.state), clipId)
                .then(this.queueSimpleSave)
        }
        else {
           journalDataService.markAsNotDiscovered(getJournalId(this.state), clipId)
                .then(this.queueSimpleSave)
        }
    }

}
