import {SyncCommunicationChannel} from "../../common/function/SyncCommunicationChannel";
import {SyncConnectionConfig, Identity, PinType} from "../domain/ApiTypes";

export enum AppMode {
    LOBBY = 1,
    EDIT_ADVENTURE,
    RUN_GAME,
    PLAY_GAME,
    EDIT_PLAYER,
    PLAY_GAME_SHARED
}

export enum PrimaryView {
    OVERVIEW = 1,
    CLIP,
    JOURNAL,
    SCENE,
    STAGE,
    BUILT_IN_PLAYER
}

export enum Dialog {
    CLIP = 1,
    INFORMATION,
    MEDIA,
    JOURNAL_ENTRY
}

/**
 * The Redux state of the application's UI
 * TODO clean up variables here that are no longer being used
 */
export interface UiState {
    /** The current mode for the main window */
    mode: AppMode | null,

    /** The current mode of the main Adventure or Game editor */
    primaryView: PrimaryView;

    /** The current session ID being viewed */
    viewingSessionId: string | null;

    /** The current scene ID being viewed */
    viewingSceneId: string | null;

    /** True if a new clip is being created */
    createClipDialogActive: boolean,

    /** True if a clip is being chosen to be added to a board */
    chooseClipDialogActive: boolean,

    /** True if information is being edited or added */
    informationDialogActive: boolean,

    /** True if media is being chosen */
    chooseMediaDialogActive: boolean,

    /** True if a new media is being created */
    createMediaDialogActive: boolean,

    /** True if a writing journal entry is being edited or added */
    writingEntryDialogActive: boolean,

    /** The ID of the currently selected CLIP from the overview */
    selectedClipId: string | null,  // FIXME once routes are done, this can be removed

    /** The current information ID being edited */
    editingInformationId: string | null;

    /** The current media ID being edited */
    editingMediaId: string | null;

    /** The current session ID being edited */
    editingSessionId: string | null;

    /** The current journal entry ID being edited */
    editingEntryId: string | null;

    /** The object that is going to get the added object */
    targetOwner: Identity | null;

    /** The type of the pin that is about to be added to the board */
    pinType: PinType | null;

    progressMessage: string | null

    /** Datasync config */
    commChannelConfig: SyncConnectionConfig | null
    loadOtherUpdatesSyncEvents: SyncCommunicationChannel | null
}

export const initialUiState: UiState = {
    mode: null,
    primaryView: PrimaryView.OVERVIEW,
    viewingSessionId: null,
    viewingSceneId: null,
    createClipDialogActive: false,
    chooseClipDialogActive: false,
    informationDialogActive: false,
    chooseMediaDialogActive: false,
    createMediaDialogActive: false,
    writingEntryDialogActive: false,
    selectedClipId: null,
    editingInformationId: null,
    editingMediaId: null,
    editingSessionId: null,
    editingEntryId: null,
    targetOwner: null,
    pinType: null,
    progressMessage: null,
    commChannelConfig: null,
    loadOtherUpdatesSyncEvents: null
};