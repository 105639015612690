import BaseRepository from "../BaseRepository";
import {DataType, Information, PartialMediaReference} from "../domain/ApiTypes";
import {IllegalArgumentException} from './../../common/exception/Exceptions';
import {Identity} from './../domain/ApiTypes';
import {DataService} from './DataService';

/**
 * A service to provide data services to the plot editor
 */
export class InformationDataService implements DataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository(DataType.information);
    }

    /**
     * Find a single information with the given id
     * @param {string} id the ID to find
     * @return {Information} a promise with the item that was found
     */
    public findOne = (id: string) : Promise<Information> => {
        return this.repository.findSingleItem(id);
    };

    /**
     * Creates the given information as part of this adventure
     * @param information the information to create
     */
    public create = (information: Partial<Information>, owner?: Identity) : Promise<Information> => {
        if(owner?.type !== DataType.adventure) {
            throw new IllegalArgumentException()
        }

        return this.repository.createInCollectionWithDetails(owner, information); // TODO genericize owner
    }

    /**
     * When the user changes the value of a field, this saves the change to the database and propagates it across the UI,
     * if necessary
     * @param informationId ID of item to be updated
     * @param updates the update Partial object
     */
    public updateItemFields = (informationId: string, updates: Partial<Information>) => { // TODO fix event type
        // TODO because of the drawer and how the editor fires events, I think, events are sometimes fired with a null item
        return this.repository.updateItem(informationId, updates)
    };

    /**
     * Gets all information in the given adventure
     * TODO fix for the given game
     */
    public findAll = (adventureId: string) : Promise<Information[]> =>
        this.repository.findAllInCollection(adventureId);


    /**
     * Adds the given media to the given clip
     * @param informationId the ID of clip that is to be added to
     * @param mediaId the ID of the media to be added
     * @returns a promise with the updated item
     */
    public addMedia(informationId: string, mediaId : string) {
        return this.repository.addMedia(informationId, mediaId)
    }

    /**
     * Updates the data on the given media reference
     */
    public updateMediaReference(informationId: string, mediaId : string, updates: PartialMediaReference) {
        return this.repository.updateMediaReference(informationId, mediaId, updates)
    }

    /**
     * Removes the given media from the given clip
     * @param informationId the ID of clip that is to be added to
     * @param mediaId the ID of the media to be added
     * @returns a promise with the updated item
     */
    public removeMedia(informationId: string, mediaId : string) {
        return this.repository.removeMedia(informationId, mediaId)
    }

}

export const informationDataService = new InformationDataService()