import {Changes, DataType, SyncConnectionConfig} from "../../repository/domain/ApiTypes";
import {TypedMap} from "../../repository/domain/MapTypes.ds";
import {io, Socket} from "socket.io-client";
import {DefaultEventsMap} from "@socket.io/component-emitter"


export type MessageSender = (message: any) => void
export type MessageHandler = (message: any) => void
export type ChangeHandler = (change: Changes) => void

/**
 * Base class for all synchronization events
 */
export interface SyncEvent {
    eventType: string
}

/**
 * Message wrapper around synchronization event
 */
export interface SyncEventMessage {
    source: string,
    timestamp: number,
    contextId: string,
    event: SyncEvent
}

/**
 * Provides a simple API to get synchronization events across browsers
 */
export class SyncCommunicationChannel {
    private static syncConnectionConfig: SyncConnectionConfig | undefined
    private static collectionType: string | undefined
    private static collectionId: string | undefined

    private static client: Socket

    private static channelId: string
    private static presenceId: string

    private static localChangeHandlers: ChangeHandler[] = []
    private static messageHandlers: TypedMap<MessageHandler[]> = {}

    private static logStep = 0

    /**
     * Initializes the global client and such
     * @param dataSyncConfig the configuration for the connection to the synchronization server
     * @param presenceId the ID of the logged in user/player/etc
     * @param collectionType the type of collection (adventure, game, etc) this is synchronizing
     * @param collectionId the id of the collection (adventure, game, etc) this is synchronizing
     */
    static async initialize(dataSyncConfig: SyncConnectionConfig, presenceId: string, collectionType: DataType, collectionId: string) {
        this.syncConnectionConfig = dataSyncConfig
        this.collectionType = collectionType
        this.collectionId = collectionId
        this.presenceId = presenceId
        this.channelId = `${collectionType}/${collectionId}`

        this.connectToSync(this.syncConnectionConfig)
            .then((readyClient) => {
                this.client = readyClient
                this.log(`Connected to sync server in channel ${this.channelId}; presence ID: ${this.presenceId}; client ID: ${this.client.id}`)
            })
            .catch((reason) => {
                this.log(`Failed to connect to sync server: ${JSON.stringify(reason)}`)
            })
    }


    /**
     * Adds a listener for local changes
     */
    static addLocalChangeHandler = (handler: ChangeHandler) => {
        if(!this.localChangeHandlers.includes(handler)) {
            this.localChangeHandlers.push(handler)
        }
    }

    /**
     * Removes a listener for local changes
     */
    static removeLocalChangeHandler = (handler: ChangeHandler)  => {
        this.localChangeHandlers = this.localChangeHandlers.filter(h => h === handler)
    }

    /**
     * Shares a change throughout the local listeners
     */
    public static shareLocalChange = (changes: Changes) : Promise<boolean> => {
        // this.log(`Sharing local change ${JSON.stringify(changes)}`)
        this.localChangeHandlers.forEach(h => h(changes))
        return Promise.resolve(true)
    }

    /**
     * Adds a listener for messages of a given type
     */
    public static addMessageHandler = (listenerId: string, handler: MessageHandler) => {
        const typedHandlers = this.messageHandlers[listenerId] || []
        if(!typedHandlers.includes(handler)) {
            typedHandlers.push(handler)
            this.messageHandlers[listenerId] = typedHandlers

            // this.log(`Subscribed to ${component} channel; curr count: ${this.messageHandlers[component].length}`)
        }
    }

    /**
     * Removes a listener for messages of a given type
     */
    public static removeMessageHandler = (listenerId: string, handler: MessageHandler)  => {
        let typedHandlers = this.messageHandlers[listenerId] || []
        this.messageHandlers[listenerId] = typedHandlers.filter(h => h !== handler)

        // this.log(`Unsubscribed to ${component} channel; curr count: ${this.messageHandlers[component].length}`)
    }

    /**
     * Sends the given message on the given channel to all other browsers attached to this same collection
     * and listening to this channel
     */
    public static sendMessage(listenerId: string, event: SyncEvent)  {
        try {
            if(this.isConnected() && this.syncConnectionConfig) {
                const now = Date.now()
                const message = {source: this.syncConnectionConfig.clientId, timestamp: now, contextId: listenerId, event} as SyncEventMessage
                // this.log(`Sending msg ${JSON.stringify(message.event).substring(0, 75)}`)
                this.client.emit("send", this.channelId, JSON.stringify(message))
            }
            else {
                // this.log(`Skipping ${JSON.stringify(event)}`)
            }
        }
        catch (e) {
            this.log(`Error sending event: ${JSON.stringify(e)}`)
        }
    }

    /**
     * A wrapper on the event handler that make sure the source of the message does not match the current source (i.e.,
     * a browser should not process its own messages)
     */
    private static receiveMessage = (rawMessage: any) => {
        try {
            const message = JSON.parse(rawMessage) as SyncEventMessage
            // this.log(`Received from ${message.source} at ${message.timestamp} - ${JSON.stringify(message.event).substring(0, 75)}`)
            if(message.source && message.source !== SyncCommunicationChannel.syncConnectionConfig?.clientId) {
                // this.log(`Sync recieved from ${message.source} at ${message.timestamp}:\n${JSON.stringify(message).substring(0, 75)}`)
                const handlers = this.messageHandlers[message.contextId] || []
                handlers.forEach(handle => handle(message.event))
            }
            else {
                // this.log(`Ignoring from ${event?.source} at ${event?.timestamp}`)
            }
        }
        catch (e) {
            this.log(`Error receiving event: ${JSON.stringify(e)}`)
        }
    }

    /**
     * Destroys all message channels and the global client
     */
    static destroy() {
        if(this.client) {
            this.client.emit('unsubscribe', SyncCommunicationChannel.channelId)
            this.client.disconnect()
        }
        this.syncConnectionConfig = undefined
        this.collectionType = undefined
        this.collectionId = undefined
        this.log(`Destroyed entire client`)
    }

    /**
     * Returns true if the client is connected to the syncronization server
     */
    private static isConnected = () => {
        try {
            return this.client !== null && this.client.connected
        }
        catch(e) {}
        return false
    }

    /**
     * Logs into the synchronization server
     */
    private static async connectToSync(syncConnectionConfig: SyncConnectionConfig) : Promise<Socket> {
        if (!syncConnectionConfig) return Promise.reject("Global config not set")

        const subscribe = (connectedClient: Socket) => new Promise<Socket>((resolve, reject) => {
            try {
                this.log("Subscribing to sync...")
                const subscriptionSuccessful = () => new Promise<Socket>((resolve, reject) => {
                    connectedClient.onAny((arg: any) => {
                        SyncCommunicationChannel.receiveMessage(arg)
                    })
                    this.log(`Subscribed to sync`)
                    resolve(connectedClient)
                }).then((readyClient) => resolve(readyClient))

                connectedClient.emit('subscribe', SyncCommunicationChannel.channelId, subscriptionSuccessful)

                return subscriptionSuccessful
                // TODO: Also need to subscribe for presence
            }
            catch (e) {
                this.log(`Subscribing to sync failed: ${JSON.stringify(e)}`)
                reject(e)
            }
        })

        const connect = new Promise<Socket>((resolve, reject) => {
            try {
                this.log(`Connecting to sync at ${syncConnectionConfig.url}...`)
                const maxConnectionAttempts = 3
                let connectionsAttempted = 1

                const newClient = io(
                    syncConnectionConfig.url,
                    {
                        reconnectionDelay: 60000,
                        reconnectionAttempts: maxConnectionAttempts,
                    })

                const handleConnectionResult = () => new Promise<Socket>((resolve, reject) => {
                    if(newClient.connected) {
                        this.log(`Connected to sync`)
                        resolve(newClient)
                    }
                    else {
                        connectionsAttempted++
                        if(connectionsAttempted > maxConnectionAttempts) {
                            reject("More connection attempts than allowed")
                        }
                    }

                }).then((connectedClient) =>
                    subscribe(connectedClient)
                        .then((readyClient) => resolve(readyClient)))

                newClient.on("connect", handleConnectionResult)
                newClient.on("connect_error", handleConnectionResult)

                return handleConnectionResult
            }
            catch (e) {
                this.log(`Connecting to sync failed: ${JSON.stringify(e)}`)
                reject(e)
            }
        })

        const readyClient = await connect
        return Promise.resolve(readyClient)
    }

    private static receivePresenceChange = (username: string, isLoggedIn: boolean) => {
        this.log(`Presence: ${username} logged ${isLoggedIn ? "in" : "out"}`)
    }

    /**
     * Logs a message
     */
    private static log = (s: string) => {
        console.log(`CCC: ${this.logStep}: ${s}`)
        this.logStep++
    }

} 

