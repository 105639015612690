import {SyncConnectionConfig, Identity, User} from "../../repository/domain/ApiTypes";
import {AppState, initialState} from '../../repository/state/AppState';
import {AppMode, initialUiState} from "../../repository/state/UiState";
import {UserDataService} from "../../repository/service/UserDataService";
import {ConfigurationService} from "../../repository/service/ConfigurationService";
import {v4 as uuid} from "uuid";
import {updateStateWithObjects} from "../../common/function/ActionFunctions";
import {StateAction} from "../../repository/StateAction";

/**
 * Loads the user for the initial loading of the application. Use like this:
 */
export class LoadUserAction extends StateAction {
    type = () => "LoadUserAction2"

    userDataService!: UserDataService
    configurationService!: ConfigurationService

    me!: User
    children!: Identity[]
    dataSync!: SyncConnectionConfig

    public load = () => {
        // dispatch(startStoreOperation());   // TODO put this back in

        Promise.all([
            this.userDataService.findCurrentUser(),
            this.userDataService.findCurrentUserChildren(),
            this.configurationService.dataSync(),
        ]).then(([me, children, dataSync]) => {
            this.me = me
            this.children = children
            this.dataSync =  {
                ...dataSync,
                clientId: uuid(), // TODO this should probably be moved into an "app setup" context rather than a user setup context
            } as SyncConnectionConfig
            this.queueSave()
        })
    }

    public save = (state: AppState) : AppState => {
        console.log(`Login complete for user ${this.me.accountName}`)

        return updateStateWithObjects(
            {
                ...initialState,
                user: this.me,
            },
            this.children,
            {
                ...initialUiState,
                commChannelConfig: this.dataSync,
                mode: AppMode.LOBBY,
            }
        )
    }
}
