/**
 * Increments the given number, wrapping to zero if needed
 */
import {Changes, Identity, NamedIdentity} from "../../repository/domain/ApiTypes";
import {TypedMap} from "../../repository/domain/MapTypes.ds";

export const newApiObject = (partial: any, owner?: Identity) => {
    return owner
        ? {
                owner: {
                    id: owner.id,
                    type: owner.type
                },
                partial: partial
        }
        : {
            partial: partial
        }
}

export const incrementWithWrap = (current: number, size: number) => {
    let next = current + 1;
    if(next >= size) {
        next = 0
    }
    return next;
};

/**
 * Decrements the number, wrapping to the max value if needed
 */
export const decrementWithWrap = (current: number, size: number) => {
    let next = current - 1;
    if(next < 0) {
        next = size - 1;
    }
    return next;
};

/**
 * Returns the max of two numbers
 */
export const max = (left: number, right: number) =>
    left > right ? left : right;

/**
 * Returns the min of two numbers
 */
export const min = (left: number, right: number) =>
    left < right ? left : right;

/**
 * Builds a complete URL given a base URL and query params
 */
export const buildUrlWithQuery = (baseUrl: string, queryParams: string[]) =>
    `${baseUrl}?${queryParams.join("&")}`

/**
 * Finds if an array contains an identity
 */
export const containsIdentity = (array: Identity[], itemToSearchFor: Identity) : boolean => {
    return array.find(a => a.id === itemToSearchFor.id) !== undefined
}

/**
 * Finds if an array doesn't contain an identity
 */
export const notContainsIdentity = (array: Identity[], itemToSearchFor: Identity) : boolean => {
    return array.find(a => a.id === itemToSearchFor.id) === undefined
}

/**
 * Filters all the updated items in the Changes object according to the passed in filter
 * @param changes the Changes object
 * @param meetsFilter a function returning true to include the object in the filtered results
 */
export const changesUpdatesFilter = (changes: Changes, meetsFilter: (identity: Identity) => boolean) : Identity[] =>
    ((changes.others ?? []).concat(changes.primary ? [changes.primary] : [])).filter(meetsFilter)

/**
 * Filters all the deleted items in the Changes object according to the passed in filter
 * @param changes the Changes object
 * @param meetsFilter a function returning true to include the object in the filtered results
 */
export const changesDeletionsFilter = (changes: Changes, meetsFilter: (identity: Identity) => boolean) : Identity[] =>
    (changes.deletions ?? []).filter(meetsFilter)

/**
 * Trims the string to the given length, cutting off the extra characters on the right and adding an ellipsis
 * @param s the original string
 * @param len the maximum length of the returned string, including an ellipsis if needed
 */
export const trimStringWithEllipsis = (s: string, len: number) : string =>
    s.length + 3 > len ? `${s.substring(0, len - 3)}...` : s

/**
 * Returns true if the number is a field internal navigation key
 */
export const isFieldInternalNavigationKey = (keyCode: number) =>
     keyCode === 8 || keyCode === 46 || keyCode === 39 || keyCode === 37

/**
 * Returns true if the number is a field "finished editing" key
 */
export const isFieldSaveEditingKey = (keyCode: number) =>
    keyCode === 13 || keyCode === 9

/**
 * Returns true if the number is a field "finished editing" key
 */
export const isFieldCancelEditingKey = (keyCode: number) =>
    keyCode === 27

/**
 * Returns true if the number is a field "finished editing" key
 */
export const isFieldModifierKey = (keyCode: number) =>
    keyCode === 16 || keyCode === 17

/**
 * Returns true if the number is a field "plus" key
 */
export const isFieldPlusKey = (keyCode: number) =>
    keyCode === 187 || keyCode === 107

/**
 * Returns true if the number is a field "minus" key
 */
export const isFieldMinusKey = (keyCode: number) =>
    keyCode === 189 || keyCode === 109

export const js = (obj: any) =>
    JSON.stringify(obj)


export const sortByName = (a: NamedIdentity, b: NamedIdentity) => a.name.localeCompare(b.name)

/**
 * Gathers all the values from the given object, skipping any that are functions and any that have the
 * same name as one of the "keysToSkip" parameter, and returns them in a map
 */
export const getValuesFromObject = (object: any, keysToSkip: string[]) : TypedMap<any> => {
    const map: TypedMap<any> = {}
    for (const [key, value] of Object.entries(object)) {
        if(!(value instanceof Function) && !keysToSkip.find(k => k === key)) {
            map[key] = value
        }
    }

    return map
}

/**
 * Sets the given map of values as variables in the given object
 */
export const setValuesInObject = (object: any, values: TypedMap<any>) => {
    const keys = Object.keys(object)
    for (const [key, value] of Object.entries(values)) {
        if(keys.find(k => k === key)) {
            object[key] = value
        }
    }
}

/**
 * Returns true if this object implements the Changes interface
 */
export const implementsChanges = (object: any) : object is Changes => {
    return object.primary || object.others || object.deletions
}


/**
 * Returns true if this object implements the Identity interface
 */
export const implementsIdentity = (object: any) : object is Identity => {
    return !!object.type
}

/**
 * Sleeps for the given number of millis
 */
export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}
