import BaseRepository from "../BaseRepository";
import {CURRENT_SESSION_TYPE, ENTRIES_TYPE, SESSIONS_TYPE} from "../domain/ApiConstants";
import {Changes, DataType, NewLearning} from "../domain/ApiTypes";
import {ApiContext} from "./ApiContext";

/**
 * A service to provide data services for journals
 */
export class JournalDataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository(DataType.journal);
    }

    /**
     * Finds this game's journal
     */
    public find = (journalId: string, context?: ApiContext) =>
        this.repository.findSingleItem(journalId, context);

    /**
     * Finds all the sessions for the given Journal
     */
    public findSessions = (journalId: string) => {
        return this.repository.findSubObjects(journalId, SESSIONS_TYPE);
    };

    /**
     * Finds all the entries for the given Journal
     */
    public findEntries = (journalId: string) => {
        return this.repository.findSubObjects(journalId, ENTRIES_TYPE);
    };

    /**
     * Adds a new session starting now to the given journal
     * @returns a promise returning the new session
     */
    public addNewSession = (journalId: string) => {
        return this.repository.addNewSubObject(journalId, DataType.session)
    };

    /**
     * Sets the given session as the current session
     */
    public setCurrentSession = (journalId: string, sessionId: string) => {
      return this.repository.setSubObjectToReference(journalId, CURRENT_SESSION_TYPE, sessionId)
    };

    /**
     * Marks the given clip as discovered, adding an journal entry in the given session
     */
    public markAsDiscovered = (journalId: string, clipId: string): Promise<Changes> => {
        return this.repository.addNewSubObjectWithReference(journalId, DataType.discovery, clipId)
    };

    /**
     * Marks the given information as learned, adding an journal entry in the given session
     */
    public markAsLearned = (journalId: string, infoId: string, fromId?: string): Promise<Changes> => {
        return this.repository.addNewSubObjectWithBody(journalId, DataType.learning, {informationId: infoId, fromId: fromId} as NewLearning)
    };

    /**
     * Marks the given clip as not discovered, removing the journal entry in the given journal
     */
    public markAsNotDiscovered = (journalId: string, clipId: string): Promise<Changes> => {
        return this.repository.deleteSubObjectWithReference(journalId, DataType.discovery, clipId)
    };

    /**
     * Marks the given information as learned, adding an journal entry in the given session
     */
    public markAsNotLearned = (journalId: string, infoId: string): Promise<Changes> => {
        return this.repository.deleteSubObjectWithReference(journalId, DataType.learning, infoId)
    };

}

export const journalDataService = new JournalDataService()