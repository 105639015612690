/**
 * Thrown when a call expects a something to be loaded with the relevant data, but it isn't
 */
export class DataNotLoadedException extends Error {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
        this.name = DataNotLoadedException.name; // stack traces display correctly now
    }
}

/**
 * Thrown when there is a problem with the system setup
 */
export class SystemSetupException extends Error {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
        this.name = SystemSetupException.name; // stack traces display correctly now
    }
}

/**
 * Thrown when there is a problem with the some data passed 
 */
export class IllegalArgumentException extends Error {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
        this.name = IllegalArgumentException.name; // stack traces display correctly now
    }
}