import { SyncConnectionConfig } from './../domain/ApiTypes';
import BaseRepository from '../BaseRepository';

/**
 * A service get configuration stored on the backend
 */
export class ConfigurationService {
    private readonly api: BaseRepository;

    constructor() {
        this.api = new BaseRepository("configuration"); 
    }

    /**
     * Gets the Cognito configuration 
     */
    public cognito = (): Promise<any> => {
        return this.api.findSingleItem("cognito") as Promise<any>
    };

    public dataSync = (): Promise<SyncConnectionConfig> => {
        return this.api.findSingleItem("datasync") as Promise<SyncConnectionConfig>
    }

}

export const configurationService = new ConfigurationService()