import BaseApi from '../BaseApi';
import { User } from "../domain/ApiTypes";

/**
 * A service to provide authentication services
 */
export class AuthService {
    private readonly api: BaseApi;

    constructor() {
        this.api = new BaseApi(); 
    }

    /**
     * Finishes the login by setting up the user's session
     */
    public finishLogin = (jwt: string): Promise<User> => {
        return this.api.post("/auth/login", {jwt}) as Promise<User>
    };

}

export const authService = new AuthService()