import { COLLECTION_URL_FILTER } from "./domain/ApiConstants";
import {
    Changes,
    DataType, PartialInformationReference, PartialMediaReference, Reference
} from "./domain/ApiTypes";
import { TypedMap } from "./domain/MapTypes.ds";
import {ApiContext} from "./service/ApiContext";

export default class BaseRepository {
    private jsonHeaders = {
        "Content-Type": "application/json"
    };
    private namedSearch: string = "named-like";

    private apiPrefix = "/api"
    private readonly baseUrl: string;

    constructor(dataType: string) {
        this.baseUrl =  `${this.apiPrefix}/${dataType}`
    }

    private headers = (apiContext?: ApiContext) => {
        const h: any = ({
            ...this.jsonHeaders,
        })
        if(apiContext?.adventure) {
            h.adventure = apiContext.adventure
        }
        if(apiContext?.game) {
            h.game = apiContext.game
        }
        if(apiContext?.player) {
            h.player = apiContext.player
        }
        return h
    }

    private checkResult = (response: any) => {
        switch (response.status) {
            case 401:
            case 500:
            case 504:
                console.log("Error contacting server - reloading site")
                window.location.reload()
        }
        return response.json()
    }

    private init(method: string, body?: any, apiContext?: ApiContext) {
        return body
            ? {
                method: method,
                headers: this.headers(apiContext),
                body: JSON.stringify(body)

            }
            : {
                method: method,
                headers: this.headers(apiContext),
            }
    }

    private get = (url: string, apiContext?: ApiContext) : Promise<any> => {
        return fetch(url, this.init("GET", undefined, apiContext))
            .then(this.checkResult)
    }

    private put = (url: string) : Promise<any> => {
        return fetch(url, this.init("PUT"))
            .then(this.checkResult)
    }

    private post = (url: string, body?: any) : Promise<any> => {
        return fetch(url, this.init("POST", body))
            .then(this.checkResult)
    }

    private patch = (url: string, body?: any) : Promise<any> => {
        return fetch(url, this.init("PATCH", body))
            .then(this.checkResult)
    }

    private delete = (url: string, body?: any) : Promise<any> => {
        return fetch(url, this.init("DELETE", body))
            .then(this.checkResult)
    }


    /**
     * Searches the database for the item with the given ID
     * @param id the ID to search for
     * @param apiContext the API context, if there is one
     * @return a promise with a single item, with an ID and Name
     */
    public findSingleItem = (id: string, apiContext?: ApiContext) => {
        return this.get(`${this.baseUrl}/${id}`, apiContext)
    }

    /**
     * Searches the database for the item that are name like the given text
     */
    public findNamedLike = (searchText: string) => {
        return this.get(`${this.baseUrl}/${this.namedSearch}/${searchText}`)
    }

    /**
     * Searches the database for the item, passing in the given parameters
     */
    public search = (parameters: TypedMap<string>) => {
        const queryString = Object.keys(parameters)
            .map(key => `${key}=${parameters[key]}`)
            .join('&')

        return this.get(`${this.baseUrl}/search?${queryString}`)
    }

    /**
     * Adds a new unnamed item to the database
     * @returns a promise with the added item
     */
    public create = () => {
        return this.post(`${this.baseUrl}`)
    }

    /**
     * Adds a new item to the database with the given details
     * @returns a promise with the added item
     */
    public createWithDetails = (details: any) => {
        return this.post(`${this.baseUrl}`, details)
    }

    /**
     * Adds a new item to the database with the given details
     * @returns a promise with the added item
     */
    public createWithDetailsAndReference = (details: any, referenceType: string, referenceId: string) => {
        return this.post(`${this.baseUrl}/${referenceType}/${referenceId}`, details)
    }

    /**
     * Finds all sub objects with the given type
     * @returns a promise with the objects
     */
    public findAllSubObjects = (subObjectType: string) => {
        return this.get(`${this.baseUrl}/${subObjectType}`)
    }

    /**
     * Finds all sub objects for the given object with the given type
     * @returns a promise with the objects
     */
    public findSubObjects = (id: string, subObjectType: string) => {
        return this.get(`${this.baseUrl}/${id}/${subObjectType}`)
    }

    /**
     * Finds all sub objects for the given object with the given type and the given search parameter
     * @returns a promise with the objects
     */
    public searchForSubObjects = (id: string, subObjectType: string, parameterName: string, parameterValue: string) => {
        return this.get(`${this.baseUrl}/${id}/${subObjectType}?${parameterName}=${parameterValue}`)
    }

    /**
     * Finds the item in the database with the given parameters
     */
    public findNamedObjectsWithParameters = (objectName: string, parameters: TypedMap<string>) => {
        const queryString = Object.keys(parameters)
            .map(key => `${key}=${parameters[key]}`)
            .join('&')

        return this.get(`${this.baseUrl}/${objectName}?${queryString}`)
    }

    /**
     * Adds a new object to the database as part of the current data type, calling the given function on return
     * @returns a promise with the added object
     */
    public addNewSubObject = (id: string, subObjectType: string) => {
        return this.post(`${this.baseUrl}/${id}/${subObjectType}`)
    }

    /**
     * Adds the sub object with the given id to the given id
     * @returns a promise with the added object
     */
    public addNewSubObjectById = (id: string, subObjectId: string) => {
        return this.put(`${this.baseUrl}/${id}/${subObjectId}`)
    }

    /**
     * Adds a new object with the given data to the database as part of the current data type, calling the given function on return
     * @returns a promise with the added object
     */
    public addNewSubObjectWithBody = (id: string, subObjectType: string, body: object) => {
        return this.post(`${this.baseUrl}/${id}/${subObjectType}`, body)
    }

    /**
     * Adds a new object to the database as part of the current data type, referring to the given id,
     * calling the given function on return
     * @returns a promise with the added object
     */
    public addNewSubObjectWithReference = (id: string, subObjectType: string, subObjectId: string) => {
        return this.post(`${this.baseUrl}/${id}/${subObjectType}/${subObjectId}`)
    }

    /**
     * Adds a new object to the database as part of the current data type, referring to the given id,
     * calling the given function on return
     * @returns a promise with the added object
     */
    public addNewSubObjectWithReferenceAndBody = (id: string, subObjectType: string, subObjectId: string, body: object) => {
        return this.post(`${this.baseUrl}/${id}/${subObjectType}/${subObjectId}`, body)
    }

    /**
     * Sets the sub object with the given type to the given id,
     * calling the given function on return
     * @returns a promise with the added object
     */
    public setSubObjectToReference = (id: string, subObjectType: string, subObjectId: string) => {
        return this.put(`${this.baseUrl}/${id}/${subObjectType}/${subObjectId}`)
    }

    /**
     * Updates the given sub-object with the given body
     */
    public updateSubObjectWithBody = (id: string, subObjectType: string, subObjectId: string, body: object) => {
        return this.patch(`${this.baseUrl}/${id}/${subObjectType}/${subObjectId}`, body)
    }

    /**
     * Deletes the sub object with the given type to the given id,
     * calling the given function on return
     * @returns a promise with the updated object
     */
    public deleteSubObjectWithReference = (id: string, subObjectType: string, subObjectId: string) => {
        return this.delete(`${this.baseUrl}/${id}/${subObjectType}/${subObjectId}`)
    }

    /**
     * Deletes the sub object with the given type to the given id,
     * calling the given function on return
     * @returns a promise with the updated object
     */
    public deleteSubObject = (id: string, subObjectType: string) => {
        return this.delete(`${this.baseUrl}/${id}/${subObjectType}`)
    }

    /**
     * Deletes the sub object with the given id from the given id
     * @returns a promise with the updated object
     */
     public deleteSubObjectById = (id: string, objectId: string) => {
        return this.delete(`${this.baseUrl}/${id}/${objectId}`)
    }

    /**
     * Deletes the subobjects of the given object, where the subobjects are described in the given body
     */
    public deleteNewSubObjectsDescribedInBody = (id: string, subObjectType: string, body: object) => {
        return this.delete(`${this.baseUrl}/${id}/${subObjectType}`, body)
    }


    /**
     * Adds a new unnamed item to the database, calling the given function on return
     * @returns a promise with the added item
     */
    public createInCollection = (collectionId: string) => {
        return this.post(`${this.baseUrl}`,{ownerId: collectionId})
    }

    /**
     * Adds a new unnamed item to the database, calling the given function on return
     * @returns a promise with the added item
     */
    public createInCollectionWithDetails = (owner: Reference, details: any) => {
        return this.post(`${this.baseUrl}`,{owner, partial: details})
    }

    /**
     * Updates a field on the given item
     * @param id the ID of the item that is to be updated
     * @param updatedItem the item with any updated fields updated
     * @returns a promise with the updated item
     */
    public updateItem = (id: string, updatedItem: any) : Promise<any> => {
        return this.patch(`${this.baseUrl}/${id}`, updatedItem)
    }

    /**
     * Deletes the item with the given ID
     * @returns a promise with the added item
     */
    public deleteItem = (id: string) => {
        return this.delete(`${this.baseUrl}/${id}`)
    }

    /**
     * Returns all items in the datastore
     * @return a promise with an array of items, each with an ID and Name
     */
    public findAllItems = () => {
        return this.get(`${this.baseUrl}`)
    }

    /**
     * Returns all items in the datastore in the given collection
     * @return a promise with an array of items, each with an ID and Name
     */
    public findAllInCollection = (collectionId: string) => {
        return this.get(`${this.baseUrl}?${COLLECTION_URL_FILTER}=${collectionId}`)
    }

    /**
     * Returns all items in the datastore in the given collection
     * @return a promise with an array of items, each with an ID and Name
     */
    public findAllWithFilter = (filterName: string, filterId: string) => {
        return this.get(`${this.baseUrl}?${filterName}=${filterId}`)
    }


    /**
     * Returns all items with the given subtype in the datastore in the given collection
     * @return a promise with an array of items, each with an ID and Name
     */
    public findAllSubtypeItemsInCollection = (collectionId: string, subtype: string) => {
        return this.get(`${this.baseUrl}/${subtype}?${COLLECTION_URL_FILTER}=${collectionId}`)
    }

    /**
     * Returns all items with one of the given subtypes in the datastore in the given collection that match the given text
     * @return a promise with an array of identities
     */
    public findMatchingItemsInCollection = (collectionId: string, types: DataType[], searchText: string) => {
        return this.get(`${this.baseUrl}?${COLLECTION_URL_FILTER}=${collectionId}&types=${types.join()}&text=${searchText}`)
    }

    /**
     * Returns all items with the given subtype in the datastore in the given adventure
     * @return a promise with an array of items, each with an ID and Name
     */
    public searchForSubtype = (subtype: string, searchText: string) => {
        return this.get(`${this.baseUrl}/${subtype}?text=${searchText}`)
    }

    /**
     * Adds a new item as a part of the given item
     * @param id the ID of the item that is to be added to
     * @returns a promise with the updated item
     */
    public addNewPart = (id: string) => {
        return this.post(`${this.baseUrl}/${id}/part`)
    }

    /**
     * Adds a new item as a part of the given item in the given adventure
     * @param id the ID of the item that is to be added to
     * @param adventureId the adventure to create the part in
     * @returns a promise with the updated item
     */
    public addNewPartInAdventure = (id: string, adventureId: string) => {
        return this.post(`${this.baseUrl}/${id}/part/${DataType.adventure}/${adventureId}`)
    }

    /**
     * Adds an existing item as a part of the given item
     * @param id the ID of the item that is to be added to
     * @param partId the ID of the part to be added
     * @returns a promise with the updated item
     */
    public addExistingAsPart = (id: string, partId: string) => {
        return this.put(`${this.baseUrl}/${id}/part/${partId}`)
    }

    /**
     * Removes a plot item from the list of parts
     * @param partOfId the ID of the item that is to removed
     * @param partId the ID of the part to be removed from
     * @returns a promise with the updated item
     */
    public removePartFromPlot = (partOfId: string, partId: string) => {
        return this.delete(`${this.baseUrl}/${partOfId}/part/${partId}`)
    }
    
    /**
     * Adds the given media to the given object
     * @param id the id of the object to add the media to
     * @param mediaId the id of the media
     */
    public addMedia = (id: string, mediaId: string) : Promise<Changes> => {
            return this.post(`${this.baseUrl}/${id}/media/${mediaId}`)
    }


    /**
     * Updates the given media reference on the given clip
     */
    public updateMediaReference = (id: string, mediaReferenceId: string, updates: PartialMediaReference) => {
        return this.patch(`${this.baseUrl}/${id}/media/${mediaReferenceId}`, updates)
    }

    /**
     * Removes the given media from the given object
     * @param id the id of the object to add the information to
     * @param mediaId the id of the media
     */
    public removeMedia = (id: string, mediaId: string) => {
        return this.delete(`${this.baseUrl}/${id}/media/${mediaId}`)
    }

    /**
     * Adds the given information to the given object
     * @param id the id of the object to add the information to
     * @param informationId the id of the media
     */
    public addInformation = (id: string, informationId: string) => {
        return this.post(`${this.baseUrl}/${id}/information/${informationId}`)
    }

    /**
     * Updates the given information reference on the given clip
     */
    public updateInformationReference = (id: string, informationId: string, updates: PartialInformationReference) => {
        return this.patch(`${this.baseUrl}/${id}/information/${informationId}`, updates)
    }

    /**
     * Removes the given information from the given object
     * @param id the id of the object to add the information to
     * @param informationId the id of the information
     */
    public removeInformation = (id: string, informationId: string) => {
        return this.delete(`${this.baseUrl}/${id}/information/${informationId}`)
    }

    public startLogin = () => {
        return this.get(`${this.baseUrl}/auth/start-login`)
    }

}
