import BaseRepository from "../BaseRepository";
import { Adventure, DataType, Identity, PartialAdventure } from "../domain/ApiTypes";
import { DataService } from "./DataService";

/**
 * A service to provide data services to the plot editor
 */
export class AdventureDataService implements DataService {
    private readonly repository: BaseRepository;

    constructor() {
        this.repository = new BaseRepository(DataType.adventure);
    }

    /**
     * Saves the update to the adventure with the given ID
     */
    public updateItemFields = (adventureId: string, updated: PartialAdventure | Partial<Adventure>): Promise<Adventure> => {
        return this.repository.updateItem(adventureId, updated) as Promise<Adventure>
    };
    

    /**
     * Finds all items
     * @return Clip[]
     */
    public findAll = () : Promise<Adventure[]> => {
        return this.repository.findAllItems();
    };

    /**
     * Find a single item with the given id
     * @param {string} id the ID to find
     * @return {Promise with Clip} the item that was found
     */
    public findOne = (id: string) => {
        return this.repository.findSingleItem(id);
    };

    /**
     * Finds all child data of the given adventure
     */
    public findChildren = (adventureId: string) : Promise<Identity[]> => 
        this.repository.findSubObjects(adventureId, "children")
     

    /**
     * Adds a new unnamed
     * @returns a promise returning the updated item
     */
    public create = () : Promise<Adventure> => {
        return this.repository.create()
    };

    /**
     * Removes an item from being part of the given item
     * @param id the ID to be removed
     * @returns a promise with the updated items
     */
    public delete(id: string) {
        return this.repository.deleteItem(id)
    }

    /**
     * Adds the given clip as a bookmark
     */
    public addBookmark(adventureId: string, clipId: string) {
        return this.repository.addNewSubObjectWithReference(adventureId, DataType.bookmark, clipId)
    }
   
    /**
     * Removes the given clip from the bookmarks
     */
    public removeBookmark(adventureId: string, clipId: string) {
        return this.repository.deleteSubObjectWithReference(adventureId, DataType.bookmark, clipId)
    }
    

}

export const adventureDataService = new AdventureDataService()