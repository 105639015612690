import BaseRepository from "../BaseRepository";
import {Adventure, DataType, Identity, PartialPlayer, Player} from "../domain/ApiTypes";
import { DataService } from "./DataService";
import {newApiObject} from "../../common/function/MiscFunctions";

/**
 * A service to provide data services for retrieving, updating, deleting Players
 */
export class PlayerDataService implements DataService {
    private readonly repository: BaseRepository;
    private readonly currentCharacter = "currentCharacter"

    constructor() {
        this.repository = new BaseRepository(DataType.player);
    }

    /**
     * Finds a single player, by ID
     */
    public findOne = (playerId: string) => {
        return this.repository.findSingleItem(playerId)
    }

    /**
     * Finds all players owned by the current user
     */
     public findAll = (): Promise<Player[]> => {
        return this.repository.findAllItems();
    };


    /**
     * Finds all player participating in the given game
     */
    public findAllParticipants = (gameId: string) : Promise<Player> => 
        this.repository.findAllWithFilter(DataType.game, gameId)

    /**
     * Finds all child data of the given player
     */
    public findChildren = (playerId: string) : Promise<Identity[]> => 
         this.repository.findSubObjects(playerId, "children")
     
        
    /**
     * Adds a new unnamed game referring to the given adventure
     * @returns a promise returning the updated item
     */
    public create = (partialPlayer: Partial<Player>, owner?: Identity) => {
        return this.repository.createWithDetails(newApiObject(partialPlayer, owner))
    };


    /**
     * Saves the given updates to the game with the given ID
     */
    public updateItemFields = (playerId: string, updated: PartialPlayer | Partial<Player>): Promise<Player> => {
        return this.repository.updateItem(playerId, updated) as Promise<Player>
    };

    /**
     * Completely deletes a Player
     * @param id the ID to be removed
     */
    public delete = (id: string) => {
        return this.repository.deleteItem(id)
    };

    /**
     * Sets the current character for the given player
     * @param id the ID of the player
     * @param characterId the ID of the character
     */
    public setCurrentCharacter = (id: string, characterId: string) : Promise<Player> =>
        this.repository.setSubObjectToReference(id, this.currentCharacter, characterId)

    /**
     * Removes the current character for the given player
     * @param id the ID of the player
     */
    public clearCurrentCharacter = (id: string) : Promise<Player> =>
        this.repository.deleteSubObject(id, this.currentCharacter)

}

export const playerDataService = new PlayerDataService()