import createCachedSelector from "re-reselect";
import {DataNotLoadedException} from "../../common/exception/Exceptions";
import {DataType, Identity, Player} from "../../repository/domain/ApiTypes";
import {TypedMap} from "../../repository/domain/MapTypes.ds";
import {AppState} from "../../repository/state/AppState";

/**
 * Returns true if the given Identity is a Player
 */
export const isPlayer = (identity: Identity | undefined | null) =>
    (identity !== null && identity !== undefined) && identity.type === DataType.player
export const filterPlayers = (identities: Identity[]) => identities.filter(c => isPlayer(c)).map(c => c as Player)

/**
 * Returns the currently loaded player
 */
 export const getPlayer = (state: AppState) : Player => {
    if(state.player) return state.player;
    throw new DataNotLoadedException()
};

/**
 * Returns the currently loaded player or null
 */
export const getPlayerOrNull = (state: AppState) : Player | null => {
    if(state.player) return state.player
    return null
};

export const getAllParticipantsMap = (state: AppState) : TypedMap<Player> => state.participantPlayers

export const getParticipant = (state: AppState, id: string) : Player => state.participantPlayers[id]

export const getAllPlayersImPlayingMap = (state: AppState): TypedMap<Player> => {
    if (state?.lobby?.playersImPlaying) {
        return state.lobby.playersImPlaying
    }
    throw new DataNotLoadedException()
};

export const getPlayerImPlaying = createCachedSelector(
    getAllPlayersImPlayingMap,
    (state: AppState, playerId: string) => playerId,
    (allPlayers, playerId) => allPlayers[playerId],
)(
    (_, playerId) => playerId
);
