import { Container, DisplayObject } from "@pixi/display";
import {FederatedPointerEvent, IHitArea} from "@pixi/events";
import {Clip, ClipProxy, Identity, Media, NamedIdentity, Pin} from "../../../repository/domain/ApiTypes";
import { BoardTags } from '../function/BoardTypes';
import AbstractPinView from './AbstractPinView';

export default abstract class DraggablePinView extends AbstractPinView {

    protected screen: Container | undefined

    protected constructor(view: Container, pin: Pin, pinnedObject: NamedIdentity, proxy: ClipProxy | undefined, media: Media, allowedTags: string[]) {
        super(view, pin, pinnedObject, proxy, media, allowedTags)

        this.view.interactive = true;
        //container.buttonMode = true;

        // this.view.on('click', this.pinClicked)
        // this.view.on('pointerdown', this.dragStarted)
        // console.log(`Registered listeners for pin ${pin.id}`)
    }

    public setScreen = (screen: Container): DraggablePinView => {
        this.screen = screen
        return this
    }

    // public moveOnScreen = (screenPoint: Point) : void => {
    //     this.view.position.set(screenPoint.x, screenPoint.y)
    //     this.pin.x = worldPoint.x
    //     this.pin.y = worldPoint.y
    //     this.log(`Moved to screen point ${screenPoint}`)
    // }

    private refuseTouches = () =>
        (this.hasTag(BoardTags.locked) || !this.hasTag(BoardTags.owned)) && !this.hasTag(BoardTags.friendly) && !this.hasTag(BoardTags.player)

    private pinClicked = (event: any) =>
    {
        this.log("Pin clicked")
        if(this.refuseTouches()) return

        const e = event as FederatedPointerEvent
        e.stopPropagation()

        this.log("Pin selected")
        if(this.handlePinClicked) this.handlePinClicked(this, e)
    }

    private dragStarted = (event: any) =>
    {
        if(this.refuseTouches()) return

        const e = event as FederatedPointerEvent
        e.stopPropagation()

        if(this.handlePinClicked) this.handlePinClicked(this, e)
        if(this.handlePinPressed) this.handlePinPressed(this, e);
        this.log("Started dragging")
    }

    
    // private dragMoved = (event: any) =>
    // {
    //     const e = event as FederatedPointerEvent
    //     e.stopPropagation()
        
    //     if (this.dragging)  // TODO can remove this if statement right?
    //     {
    //         this.view.parent.toLocal(event.global, undefined, this.view.position);
                    
    //         var newPosition = this.view.position
    //         this.log(`Dragging... ${Math.round(newPosition.x)}, ${Math.round(newPosition.y)}`)
    //         this.moveInWorld(newPosition)
    //     }

    //     e.preventDefault()

    // }

    // private dragEnded = (event: any) => {
    //     const e = event as FederatedPointerEvent
    //     e.stopPropagation()

    //     this.dragging = false
    //     this.log(`Stopped dragging; ${this.view.position.x}, ${this.view.position.y}`)
    //     if(this.screen) this.screen.removeEventListener('pointermove', this.dragMoved);
    //     e.preventDefault()
    //     if(this.handleAfterMoveComplete) this.handleAfterMoveComplete(this.pin, this.view.position)
    // }

    abstract buildHitArea() : IHitArea

}
